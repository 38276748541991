import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IApiPlaylist, IApiPlaylistScreen, IScreenMetadata} from '../../../../services/interfaces/IApiPlaylist';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IApiScreenType} from '../../../../services/interfaces/IApiConfig';
import {IApiAccountInfoSubscription} from '../../../../services/interfaces/IApiAccountInfo';
import {ScreenSelectorComponent} from './screen-selector/screen-selector.component';
import {ScreenConfiguratorComponent} from './screen-configurator/screen-configurator.component';
import { IApiMedia } from 'src/app/services/interfaces/IApiMedia';

export interface MediaSelectorData {
  screen: IApiPlaylistScreen;
  type: string;
  selected: string[];
  singleSelection?: boolean;
}

@Component({
  selector: 'playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [style({ height: 0 }), animate(200)]),
      transition(':leave', [animate(200, style({ height: 0 }))]),
    ]),
  ]
})
export class PlaylistComponent implements OnInit {
  @ViewChild('confirmDelete') deleteRef: TemplateRef<any>
  private deleteDialogRef: MatDialogRef<any>;
  private allPlaylists: IApiPlaylist[];
  private allMedia: IApiMedia[];

  screenTypes: { [key: string]: IApiScreenType };

  nameFormControl: FormControl;
  tickerFormControl: FormControl;

  premiumIntegrations: string[] = [];
  canPickPremium: boolean;
  playlist: IApiPlaylist;
  loaded: boolean;

  showingConfigure: boolean = false;
  showingTicker: boolean = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {

  }

  ngOnInit() {
    this.screenTypes = {};
    this.apiService.getConfig().subscribe(result => {
      result.screenTypes.map(x => { this.screenTypes[x.id] = x });

      if (this.playlist) {
        this.loaded = true;
      }
    });

    let playlistFromHistory: IApiPlaylist = history.state.playlist;
    this.apiService.getConfig().subscribe(config => {
      this.premiumIntegrations = config.premiumIntegrations;
    });

    this.route.paramMap.subscribe(params => {
      if (playlistFromHistory && playlistFromHistory._id === params.get('id')) {
        this.handlePlaylistLoad(playlistFromHistory);
      }

      this.apiService.getInfo().subscribe(info => {
        this.canPickPremium = (info.subscription as IApiAccountInfoSubscription).data.limitations.premiumIntegrations;
        if (!info.kamarAuth) {
          delete this.screenTypes['kamarcalendar'];
          delete this.screenTypes['kamarnotices'];
        }
        this.allPlaylists = info.playlists;
        this.allMedia = info.media;

        if (!this.playlist) {
          let playlist = info.playlists.find(x => x._id === params.get('id'));
          if (playlist) {
            this.handlePlaylistLoad(playlist);
          } else {
            this.router.navigate(['../'], {relativeTo: this.route});
          }
        }
      });
    });
  }

  handlePlaylistLoad(playlist: IApiPlaylist) {
    this.playlist = playlist;
    this.playlist.transitionType = this.playlist.transitionType ?? 'none';
    if (Object.keys(this.screenTypes).length) {
      this.loaded = true;
    }

    this.nameFormControl = new FormControl(this.playlist.name, [
      Validators.min(3)
    ]);

    this.tickerFormControl = new FormControl(this.playlist.ticker.text, []);

    this.playlist.ticker.text = this.playlist.ticker.text ?? '';
    this.showingTicker = this.playlist.ticker.enabled;
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    moveItemInArray(this.playlist.screens, event.previousIndex, event.currentIndex);

    this.saveChanges();
  }

  onAddScreenClick() {
    this.dialog.open(ScreenSelectorComponent, {
      width: '80%',
      disableClose: true,
    }).afterClosed().subscribe((data: IApiPlaylistScreen[]) => {
      if (data.length) {
        this.playlist.screens = this.playlist.screens.concat(data);
        this.saveChanges();
      }
    });
  }

  onNameChange() {
    let dupe = this.allPlaylists.find(x => x.name.toLowerCase() === this.nameFormControl.value.toLowerCase());
    if (dupe && dupe._id !== this.playlist._id) {
      this.nameFormControl.setErrors({ invalid: true });
      return;
    }
  }

  saveNewName() {
    if (this.nameFormControl.invalid || this.tickerFormControl.invalid) {
      return;
    }

    this.playlist.name = this.nameFormControl.value;
    // if (!this.showingTicker) {
    //   this.tickerFormControl.setValue('');
    // }
    // this.playlist.ticker.enabled = this.showingTicker;
    this.playlist.ticker.text = this.tickerFormControl.value;
    this.saveChanges();
  }

  beginScreenCancel(screen: IApiPlaylistScreen) {
    this.deleteDialogRef = this.dialog.open(this.deleteRef);
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let index = this.playlist.screens.indexOf(screen);
        if (index !== -1) {
          this.playlist.screens.splice(index, 1);
          this.saveChanges();
        }
      }
    });
  }

  cancelDeletion() {
    this.deleteDialogRef.close(false);
  }

  confirmDeletion() {
    this.deleteDialogRef.close(true);
  }

  saveChanges() {
    this.apiService.savePlaylist(this.playlist).subscribe(result => {
      if (result.status) {
        this.snackBar.open('Changes successfully saved!', null, {
          duration: 3000
        });
      } else {
        this.snackBar.open('Failed to save changes.', null, {
          duration: 3000
        });
      }
    })
  }

  doesScreenHaveConditions(screen: IApiPlaylistScreen): boolean {
    return screen.conditions.dateBased.length > 0 || screen.conditions.dayBased.length > 0;
  }

  padWithLeadingZero(num: number): string {
    if (num < 10) {
      return `0${num}`;
    }

    return `${num}`;
  }


  dateToString(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}, ${this.padWithLeadingZero(date.getHours())}:${this.padWithLeadingZero(date.getMinutes())}`;
  }

  configureScreen(screen: IApiPlaylistScreen) {
    let screenType = this.screenTypes[screen.type];
    if (!screenType) {
      return;
    }

    this.dialog.open(ScreenConfiguratorComponent, {
      width: '600px',
      disableClose: true,
      data: {
        screen: screen,
        screenType: screenType,
      }
    }).afterClosed().subscribe((newScreen: IApiPlaylistScreen) => {
      if (!newScreen) {
        return;
      }

      let index = this.playlist.screens.indexOf(screen);
      this.playlist.screens[index] = newScreen;

      this.saveChanges();
    });
  }

  buildConditionString(screen: IApiPlaylistScreen): string {
    let str = '';

    if (!screen.conditions.dayBased.length && !screen.conditions.dateBased.length) {
      str = 'This screen displays all the time. No conditions have been set';
    } else {
      str = 'This screen has ';
      if (screen.conditions.dayBased.length) {
        str += `${screen.conditions.dayBased.length} recurring condition${screen.conditions.dayBased.length === 1 ? '' : 's'}`;

        if (screen.conditions.dateBased.length) {
          str += ' and ';
        }
      }

      if (screen.conditions.dateBased.length) {
        str += `${screen.conditions.dateBased.length} one-off condition${screen.conditions.dateBased.length === 1 ? '' : 's'}`;
      }
    }

    return str + '.';
  }

  buildMediaSelectedString(urls: string[]): string {
    let str = '';

    for (let media of this.allMedia) {
      if (urls.includes(media.location)) {
        str += media.originalname + ', ';
      }
    }
    return str.slice(0, -2);
  }

  toggleSettings() {
    this.showingConfigure = !this.showingConfigure;
  }
}
