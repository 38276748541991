<div class="container">
  <mat-card class="loading-card" *ngIf="!stripeLoaded && !plansLoaded else subs">
    <app-loading-spinner></app-loading-spinner>
  </mat-card>
</div>

<ng-template #subs>
  <div class="plan-title">
    <ng-container *ngIf="!hasSubscription else hasSub">
      <h2>Please select a plan</h2>
      <p>Select a plan to get started using POMP.</p>
    </ng-container>

    <ng-template #hasSub>
      <h2>Update your subscription plan</h2>
      <p>View other plan's benefits and change your subscribed plan here.</p>
    </ng-template>
  </div>
  <div class="row">

    

    <div class="col-lg-6 col-xl-3" *ngFor="let plan of subPlans">

      <div class="price-plan">
        <div class="price-box" [ngStyle]="{ 'background-color': plan.colour }">
          <div class="price">${{ plan.price }} <small>NZD</small></div>
          <div class="price-desc">{{ plan.name }}</div>
        </div>


        <div class="includes" [ngStyle]="{ color: plan.colour }">
          <div class="title">{{ plan.name }} includes</div>
          <div class="subtitle">All of these features</div>

          <ul>
            <li>{{ plan.limitations.devices }} devices</li>
            <li>{{ plan.limitations.playlists }} playlists</li>
            <li>{{ plan.limitations.storage }}MB storage</li>
            <li *ngIf="plan.limitations.premiumIntegrations">Premium Integrations</li>
<!--            <li *ngFor="let limitation of plan.limitations | keyvalue">-->
<!--              {{ limitation.value }} {{ limitation.key }}-->
<!--            </li>-->
          </ul>
        </div>

        <div *ngIf="plan.id !== subscriptionData.id">
          <button mat-flat-button [ngStyle]="{ 'background-color': plan.colour }" (click)="purchasePlan(plan, $event);" *ngIf="!waiting">Purchase</button>
          <button mat-flat-button [ngStyle]="{ 'background-color': plan.colour }"  class="btn btn-primary" *ngIf="waiting" disabled="">
            <app-loading-spinner style="font-size: 18px;"></app-loading-spinner>
          </button>
        </div>

        <div *ngIf="plan.id == subscriptionData.id">
          <button mat-flat-button [ngStyle]="{ 'background-color': plan.colour }" *ngIf="!waiting" disabled="">You are on this plan</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
