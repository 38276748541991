<h3>KAMAR Directory Services Credentials</h3>
<div *ngIf="data.username && data.password else noAuth">
  <div class="row">
    <label>API</label>
    <div class="info">
      {{ data.api }}
      <button mat-flat-button (click)="copyToClipboard(data.api)">Copy</button>
    </div>
  </div>
  <div class="row">
    <label>Username</label>
    <div class="info">
      {{ data.username }}
      <button mat-flat-button (click)="copyToClipboard(data.username)">Copy</button>
    </div>
  </div>
  <div class="row">
    <label>Password</label>
    <div class="info">
      {{ data.password }}
      <button mat-flat-button (click)="copyToClipboard(data.password)">Copy</button>
    </div>
  </div>
</div>

<ng-template #noAuth>
  You have not yet generated your KAMAR credentials. Click below to generate!
</ng-template>

<div class="buttons">
  <button mat-flat-button color="accent" [disabled]="regeningKamar" (click)="onRegenClick()">
    <ng-container *ngIf="!regeningKamar else loading">{{ data.username ? 'Regenerate Credentials' : 'Generate Credentials' }}</ng-container>
    <ng-template #loading>
      <app-loading-spinner style="font-size: 15px"></app-loading-spinner>
    </ng-template>
  </button>
  <button mat-flat-button color="primary" (click)="close()">Close</button>
</div>
