<div class="container">
  <div class="header" *ngIf="loaded && playlists.length !== 0">
    <h1>Your Playlists</h1>
    <button mat-flat-button color="primary" (click)="onClickCreate()">Create Playlist</button>
  </div>
  <mat-card class="loading-card" *ngIf="!loaded">
    <app-loading-spinner></app-loading-spinner>
  </mat-card>

  <div class="no-playlists" *ngIf="loaded && playlists.length == 0">
    <img src="/assets/images/playlist.svg">
    
    <h1>You haven't created a playlist yet.</h1>

    <div class="how-to">
      Get started by creating a playlist to assign to your device by clicking the button below.
    </div>

    <button mat-flat-button color="primary" (click)="onClickCreate()">Create Playlist</button>
  </div>

  <mat-accordion class="playlist-list">
    <mat-expansion-panel *ngFor="let playlist of playlists">
      <mat-expansion-panel-header>
        <mat-panel-title class="title">{{ playlist.name }}</mat-panel-title>
        <mat-panel-description class="used-by" *ngIf="getDeviceCount(playlist._id)">used by&nbsp;<strong> {{ getDeviceCount(playlist._id) }}</strong>&nbsp;{{ getDeviceCount(playlist._id) === 1 ? 'device' : 'devices' }}</mat-panel-description>
      </mat-expansion-panel-header>

      <div class="buttons">
        <button mat-flat-button color="warn" (click)="removePlaylist(playlist)">Delete Playlist</button>
        <button mat-flat-button color="primary" (click)="editPlaylist(playlist._id)">Edit Playlist</button>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #createDialog>
  <div class="create-window">
    <h1 mat-dialog-title>Create a new Playlist</h1>
    <div mat-dialog-content>
      <mat-form-field>
        <mat-label>Playlist Name</mat-label>
        <input matInput [formControl]="formControl">
        <mat-hint>Enter a name to identify your playlist</mat-hint>
        <mat-error *ngIf="!formControl.valid">Your playlist name must be at least 3 characters and unique.</mat-error>
      </mat-form-field>

      <div mat-dialog-actions class="actions">
        <button mat-flat-button (click)="cancelCreate()">Cancel</button>
        <button mat-flat-button color="primary" (click)="createPlaylist()">Create</button>
      </div>
    </div>
  </div>
</ng-template>
