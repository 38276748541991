import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BurgerService {
  private _showingBurgerMenu: boolean = false;
  constructor() { }

  isShowingBurger(): boolean {
    return this._showingBurgerMenu;
  }

  toggleMenu() {
    this._showingBurgerMenu = !this._showingBurgerMenu;
  }

  setMenuVisibility(value: boolean) {
    this._showingBurgerMenu = value;
  }
}
