<div class="top">
  <h3>{{ screenType.name }}</h3>
  <div class="premium" *ngIf="screenType.premium">Premium</div>
</div>
<p>{{ screenType.description }}</p>
<div *ngIf="warningMessage" class="warningMessage">
  {{ warningMessage }}
</div>
<mat-dialog-content>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Configuration"><ng-container [ngTemplateOutlet]="config"></ng-container></mat-tab>
    <mat-tab label="Display Conditions"><ng-container [ngTemplateOutlet]="conditionConfig"></ng-container></mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
  <button mat-flat-button color="warn" (click)="close()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()" [disabled]="formGroup.invalid" *ngIf="screenType.premium && canPickPremium">Save Screen</button>
  <button mat-flat-button color="primary" (click)="save()" [disabled]="formGroup.invalid" *ngIf="!screenType.premium">Save Screen</button>
  <div class="warningMessage" *ngIf="screenType.premium && !canPickPremium">Premium required for this screen type.</div>
</mat-dialog-actions>

<ng-template #config>
  <div class="content">
    <mat-form-field style="width: 100%;">
      <mat-label>Duration</mat-label>
      <input type="number" matInput min="10" step="1" [(ngModel)]="screen.duration">
      <mat-hint>The length of time in seconds this screen will be visible.</mat-hint>
    </mat-form-field>

    <ng-container *ngFor="let prop of screenType.properties" [ngSwitch]="prop.type">
      <div class="prop-input" *ngIf="formGroup">
        <ng-template *ngSwitchCase="'string'" [ngTemplateOutlet]="stringInput" [ngTemplateOutletContext]="{ data: prop, formControl: formGroup.controls[prop.key] }"></ng-template>
        <ng-template *ngSwitchCase="'emoji'" [ngTemplateOutlet]="emojiSelector" [ngTemplateOutletContext]="{ data: prop }"></ng-template>
        <ng-template *ngSwitchCase="'images'" [ngTemplateOutlet]="imagesInput" [ngTemplateOutletContext]="{ data: prop }"></ng-template>
        <ng-template *ngSwitchCase="'videos'" [ngTemplateOutlet]="videosInput" [ngTemplateOutletContext]="{ data: prop }"></ng-template>
        <ng-template *ngSwitchCase="'enum'" [ngTemplateOutlet]="enumInput" [ngTemplateOutletContext]="{ data: prop, formControl: formGroup.controls[prop.key], multi: false }"></ng-template>
        <ng-template *ngSwitchCase="'enumMulti'" [ngTemplateOutlet]="enumInput" [ngTemplateOutletContext]="{ data: prop, formControl: formGroup.controls[prop.key], multi: true }"></ng-template>
        <ng-template *ngSwitchCase="'hailOrganisations'" [ngTemplateOutlet]="enumInput" [ngTemplateOutletContext]="{ data: prop, formControl: formGroup.controls[prop.key] }"></ng-template>
        <ng-template *ngSwitchCase="'googleCalendars'" [ngTemplateOutlet]="enumInput" [ngTemplateOutletContext]="{ data: prop, formControl: formGroup.controls[prop.key] }"></ng-template>
        <ng-template *ngSwitchCase="'currencySelector'" [ngTemplateOutlet]="currencySelectorInput" [ngTemplateOutletContext]="{ data: prop, formControl: formGroup.controls[prop.key] }"></ng-template>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #conditionConfig>
  <div class="content">
    <button mat-flat-button color="primary" (click)="addCondition()">Add Condition</button>
    <div class="condition" *ngFor="let condition of conditions">
      <div class="top">
        <mat-checkbox [checked]="isRecurringCondition(condition)" (change)="changeConditionType(condition)" class="recurrance-check">Recurring Condition</mat-checkbox>
        <button mat-flat-button color="warn" (click)="removeCondition(condition)">Delete Condition</button>
      </div>
      <ng-container *ngIf="isRecurringCondition(condition); else oneOffCondition">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Day</mat-label>
          <mat-select [(ngModel)]="condition.dayName">
            <mat-option value="Monday">Monday</mat-option>
            <mat-option value="Tuesday">Tuesday</mat-option>
            <mat-option value="Wednesday">Wednesday</mat-option>
            <mat-option value="Thursday">Thursday</mat-option>
            <mat-option value="Friday">Friday</mat-option>
            <mat-option value="Saturday">Saturday</mat-option>
            <mat-option value="Sunday">Sunday</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="time-selection">
          <div>
            From
            <ngx-mat-timepicker [(ngModel)]="condition.start"
                                [showSeconds]="false">
            </ngx-mat-timepicker>
          </div>
          <div>
            To
            <ngx-mat-timepicker [(ngModel)]="condition.end"
                                [showSeconds]="false">
            </ngx-mat-timepicker>
          </div>
        </div>
      </ng-container>

      <ng-template #oneOffCondition>
        <div class="condition">
          <div class="time-selection">
            <mat-form-field>
              <mat-label>From</mat-label>
              <input matInput [ngxMatDatetimePicker]="fromPicker" [(ngModel)]="condition.start">
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <!--      <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"-->
              <!--                               [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"-->
              <!--                               [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian"-->
              <!--                               [disableMinute]="disableMinute" [hideTime]="hideTime">-->
              <ngx-mat-datetime-picker #fromPicker [showSeconds]="false">
              </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>To</mat-label>
              <input matInput [ngxMatDatetimePicker]="toPicker" [(ngModel)]="condition.end">
              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
              <!--      <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"-->
              <!--                               [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"-->
              <!--                               [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian"-->
              <!--                               [disableMinute]="disableMinute" [hideTime]="hideTime">-->
              <ngx-mat-datetime-picker #toPicker [showSeconds]="false">
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #emojiSelector let-data="data">
  <div class="emoji-selection">
    <div class="current-emoji" *ngIf="screen.metadata.hasOwnProperty(data.key); else noEmoji">{{ screen.metadata[data.key] }}</div>
    <ng-template #noEmoji>No Emoji selected</ng-template>
    <button mat-flat-button color="accent" (click)="openEmojiSelector(data)">{{ screen.metadata.hasOwnProperty(data.key) ? 'Select another Emoji' : 'Select an Emoji' }}</button>
  </div>
</ng-template>

<ng-template #currencySelectorInput let-data="data" let-formControl="formControl">
    <mat-form-field class="w-100">
      <mat-select [formControl]="formControl" placeholder="Currencies" [multiple]="true" #multiSelect>
        <mat-option *ngFor="let currency of currencyTypes | async" [value]="currency.id">
          {{currency.name}} ({{currency.id}})
        </mat-option>
      </mat-select>
      <mat-error *ngIf="formControl.hasError('required')">This field is required</mat-error>
    </mat-form-field>
</ng-template>


<ng-template #stringInput let-data="data" let-formControl="formControl">
  <mat-form-field style="width: 100%;">
    <mat-label>{{ data.name }}</mat-label>
    <mat-hint>{{ data.hint }}</mat-hint>
    <input matInput [formControl]="formControl">
    <mat-error *ngIf="formControl.hasError('required')">This field is required</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #enumInput let-data="data" let-formControl="formControl" let-multi="multi">
  <mat-form-field style="width: 100%;">
    <mat-label>{{ data.name }}</mat-label>
    <mat-hint>{{ data.hint }}</mat-hint>
    <mat-select [formControl]="formControl" [multiple]="multi">
      <mat-option *ngFor="let option of data.options" [value]="option">{{ option }}</mat-option>
    </mat-select>
    <mat-error *ngIf="formControl.hasError('required')">This field is required</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #imagesInput let-data="data">
  <div class="media-selection">
    <strong>{{ data.name }}</strong>
    <div>
      {{ data.hint }}
    </div>
    <button mat-flat-button (click)="selectMedia(screen, 'images')" color="accent">Select Image Media</button>
    <div class="media-select-count"><strong>{{ screen.metadata.hasOwnProperty(data.key) ? screen.metadata[data.key].length : 0 }}</strong> images selected</div>
  </div>
</ng-template>

<ng-template #videosInput let-data="data">
  <div class="media-selection">
    <strong>{{ data.name }}</strong>
    <div>
      {{ data.hint }}
    </div>
    <button mat-flat-button (click)="selectMedia(screen, 'videos')" color="accent">Select Video Media</button>
    <div class="media-select-count"><strong>{{ screen.metadata.hasOwnProperty(data.key) ? screen.metadata[data.key].length : 0 }}</strong> videos selected</div>
  </div>
</ng-template>
