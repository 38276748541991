import {Component, NgZone, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IApiPlaylist} from '../../../services/interfaces/IApiPlaylist';
import {FormControl, Validators} from '@angular/forms';
import {IApiDevice} from '../../../services/interfaces/IApiDevice';
import {IApiMedia} from '../../../services/interfaces/IApiMedia';
import {ConfirmDeleteComponent} from '../util/confirm-delete/confirm-delete.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {IApiAccountInfoSubscription} from '../../../services/interfaces/IApiAccountInfo';

@Component({
  selector: 'app-playlist-list',
  templateUrl: './playlist-list.component.html',
  styleUrls: ['./playlist-list.component.scss'],
})
export class PlaylistListComponent implements OnInit {
  @ViewChild("createDialog") dialogRef: TemplateRef<any>;

  newPlaylistName: string = '';
  newPlaylistError: string = '';

  loaded: boolean;
  playlists: IApiPlaylist[];
  devices: IApiDevice[];

  formControl: FormControl

  private maxPlaylistCount: number;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.formControl = new FormControl(this.newPlaylistName, [
      Validators.required,
      Validators.min(3)
    ]);

    this.apiService.getInfo().subscribe(x => {
      this.loaded = true;
      this.playlists = x.playlists;
      this.devices = x.devices;

      this.maxPlaylistCount = (x.subscription as IApiAccountInfoSubscription).data.limitations.playlists;
    });
  }

  getDeviceCount(playlistId: string) {
    return this.devices.filter(x => x.playlist === playlistId).length;
  }

  editPlaylist(id: string) {
    this.router.navigate([ id ], { relativeTo: this.route, state: { playlist: this.playlists.find(x => x._id === id) } });
  }

  onClickCreate() {
    if (this.playlists.length >= this.maxPlaylistCount) {
      this.snackBar.open(`You can only create a maximum of ${this.maxPlaylistCount} playlists. Consider upgrading your subscription to create more.`, null, {
        duration: 5000
      });

      return;
    }
    this.dialog.open(this.dialogRef);
  }

  createPlaylist() {
    if (this.formControl.value.length < 3) {
      this.formControl.setErrors({ invalid: true });
      return;
    } else if (this.playlists.find(x => x.name == this.formControl.value)) {
      this.formControl.setErrors({ invalid: true });
      return;
    }
    this.newPlaylistError = '';

    this.apiService.createPlaylist(this.formControl.value).subscribe(data => {
      if (!data.success) {
        this.snackBar.open(data.response, null, {
          duration: 4000
        });

        return;
      }
      let playlist: IApiPlaylist = {
        _id: data.playlistId,
        name: this.formControl.value,
        screens: [],
        ticker: {
          enabled: false,
          text: ''
        }
      };
      this.cancelCreate();
      this.router.navigate([ data.playlistId ], { relativeTo: this.route, state: { playlist: playlist } });
    });
  }

  cancelCreate() {
    this.dialog.closeAll();
  }

  removePlaylist(playlist: IApiPlaylist) {
    this.dialog.open(ConfirmDeleteComponent, {
      width: '500px',
      data: { name: playlist.name }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deletePlaylist(playlist._id).subscribe((data: { status: boolean }) => {
          this.ngZone.run(() => {
            if (data.status) {
              let index = this.playlists.indexOf(playlist);
              if (index > -1) {
                this.playlists.splice(index, 1);

                this.snackBar.open('Playlist successfully deleted', null, {
                  duration: 3000
                });
              }
            } else {
              this.snackBar.open('Failed to delete playlist.', null, {
                duration: 3000
              });
            }
          });
        })
      }
    });
  }
}
