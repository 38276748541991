import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

const API = 'https://api.pomp.co.nz/account';


const options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _completedAuth: boolean = false;
  private _authenticated: boolean;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
    if (this.isAuthenticated()) {
      this.apiService.getInfo().subscribe(d => {
        this._completedAuth = true;
        this._authenticated = d.session;

        if (!this._authenticated) {
          this.logout();
        }
      })
    }
  }

  login(credentials: {
    email: string,
    password: string,
    captchaToken: string
  }): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('email', credentials.email);
    formData.set('password', credentials.password);
    formData.set('g-recaptcha-response', credentials.captchaToken);
    return this.http.post(`${API}/app/login`, formData.toString(), options);
  }

  register(credentials: {
    password: string,
    email: string
  }): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('email', credentials.email);
    formData.set('password', credentials.password);
    return this.http.post(`${API}/app/register`, formData.toString(), options);
  }

  logout(): Observable<any> {
    localStorage.removeItem('token');
    this._authenticated = false;

    return this.http.get(`${API}/logout`);
  }

  requestPasswordReset(email: string, recaptchaToken: string): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('email', email);
    formData.set('g-recaptcha-response', recaptchaToken);
    return this.http.post(`${API}/forgot-password`, formData.toString(), options);
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  isAuthenticated(): boolean {
    return (this._completedAuth && this._authenticated) || localStorage.getItem('token') != null;
  }
}
