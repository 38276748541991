import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../../services/api.service';
import {IApiScreenType} from '../../../../../services/interfaces/IApiConfig';
import {ScreenConfiguratorComponent} from '../screen-configurator/screen-configurator.component';
import {IApiPlaylistScreen} from '../../../../../services/interfaces/IApiPlaylist';

@Component({
  selector: 'app-screen-selector',
  templateUrl: './screen-selector.component.html',
  styleUrls: ['./screen-selector.component.scss']
})
export class ScreenSelectorComponent implements OnInit {
  loading: boolean = true;

  @ViewChild('moreInfo') private moreInfoElement: TemplateRef<HTMLElement>;
  private moreInfoDialog: MatDialogRef<HTMLElement>;

  screenTypes: IApiScreenType[];
  moreInfoScreen: IApiScreenType;

  addedScreens: IApiPlaylistScreen[];

  constructor(
    private dialogRef: MatDialogRef<ScreenSelectorComponent>,
    private dialog: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.addedScreens = [];
    this.apiService.getConfig().subscribe(result => {
      this.screenTypes = result.screenTypes;
      this.loading = false;
    });
  }

  close() {
    this.dialogRef.close(this.addedScreens);
  }

  displayMoreInfo(screen: IApiScreenType) {
    this.moreInfoScreen = screen;
    this.moreInfoDialog = this.dialog.open(this.moreInfoElement, {
      width: '70%'
    });
  }

  closeMoreInfo() {
    if (this.moreInfoDialog) {
      this.moreInfoDialog.close();
    }
  }

  addToPlaylist(screen: IApiScreenType) {
    this.dialog.open(ScreenConfiguratorComponent, {
      data: { screenType: screen },
      width: '600px'
    }).afterClosed().subscribe((screen?: IApiPlaylistScreen) => {
      if (screen) {
        this.addedScreens.push(screen);

        this.close();
      }
    });
  }
}
