<h3>Upload new Media</h3>
<div class="drop" appFileDrag (fileHovered)="onFileDropHovered($event)" (filesDropped)="handleFileInput($event)" [disabled]="uploading">
  <i class="fas fa-file-upload icon"></i>

  <div class="inner" *ngIf="!dropHovered">
    <div class="text">Drag files here to upload</div>
  </div>

  <div class="inner" *ngIf="dropHovered">
    <div class="text">Release files to upload!</div>
  </div>
</div>
<ng-container *ngIf="uploading">
  <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
</ng-container>
<button mat-flat-button color="primary" (click)="browseLocalFiles()" [disabled]="uploading">Browse Local Files</button>
<input #fileInput type="file" hidden (change)="handleFileInput($event.target.files)" multiple accept="image/jpeg, image/png, image/gif, video/mp4, video/quicktime" [disabled]="uploading">
