
<nav>
  <div class="left section">
    <a href="#" (click)="$event.preventDefault(); openDrawer()" *ngIf="mobileQuery.matches" class="hamburger"><i class="fas fa-bars"></i></a>
    <a [routerLink]="['dashboard']" class="brand"><img src="/assets/images/logo-white.svg" class="brand" draggable="false"></a>
  </div>

  <ng-container *ngIf="!isAuthenticated; else authenticated">
    <div class="right section">
      <a [routerLink]="['account', 'login']" routerLinkActive="selected">
        Login
      </a>
      <a [routerLink]="['account', 'register']" routerLinkActive="selected">
        Create Account
      </a>

    </div>
</ng-container>


  <ng-template #authenticated>
    <div class="right section authed-menu">
      <a [routerLink]="['dashboard']" routerLinkActive="selected">
        My Dashboard
      </a>
      <a [routerLink]="['account', 'settings']" routerLinkActive="selected">
        Account Settings
      </a>
      <a [routerLink]="['account', 'logout']" routerLinkActive="selected">
        Log Out
      </a>
    </div>
  </ng-template>


</nav>

<mat-drawer-container class="drawer-container" *ngIf="(!isAuthenticated && mobileQuery.matches) || isAuthenticated; else router">

  <mat-drawer #drawer [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="menuOpened || burgerService.isShowingBurger()" (closedStart)="burgerService.setMenuVisibility(false)">
    <mat-nav-list *ngIf="isAuthenticated">
      <a mat-list-item [routerLink]="['dashboard', 'devices']" routerLinkActive="mat-list-single-selected-option" (click)="onNavItemClick()"><i class="fas fa-desktop"></i> Devices</a>
      <a mat-list-item [routerLink]="['dashboard', 'playlists']" routerLinkActive="mat-list-single-selected-option" (click)="onNavItemClick()"><i class="fas fa-list"></i> Playlists</a>
      <a mat-list-item [routerLink]="['dashboard', 'media']" routerLinkActive="mat-list-single-selected-option" (click)="onNavItemClick()"><i class="fas fa-photo-video"></i> Media</a>

      <div class="authed-side-menu">
        <!-- <a mat-list-item [routerLink]="dashboard" routerLinkActive="mat-list-single-selected-option"><i class="fas fa-home"></i> My Dashboard</a> -->
        <a mat-list-item [routerLink]="['/account', 'settings']" routerLinkActive="mat-list-single-selected-option" (click)="onNavItemClick()"><i class="fas fa-cog"></i> Account Settings</a>
        <a mat-list-item [routerLink]="['/account', 'logout']" routerLinkActive="mat-list-single-selected-option" (click)="onNavItemClick()"><i class="fas fa-sign-out-alt"></i> Logout</a>
      </div>

      <!-- <a mat-list-item routerLink="payment" routerLinkActive="mat-list-single-selected-option"><i class="fas fa-file-invoice"></i> Payment Portal</a>
      <a mat-list-item routerLink="subscriptions" routerLinkActive="mat-list-single-selected-option"><i class="fas fa-receipt"></i> Your Subscription</a> -->
    </mat-nav-list>
  </mat-drawer>

  <mat-drawer-content>
    <ng-container [ngTemplateOutlet]="router"></ng-container>
  </mat-drawer-content>

</mat-drawer-container>

<ng-template #router>
  <router-outlet></router-outlet>
</ng-template>
