import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UploadMediaComponent} from './upload-media/upload-media.component';
import {ApiService} from '../../../services/api.service';
import {IApiMedia} from '../../../services/interfaces/IApiMedia';
import {ConfirmDeleteComponent} from '../util/confirm-delete/confirm-delete.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IApiAccountInfoSubscription} from '../../../services/interfaces/IApiAccountInfo';
import {CanvaPopupComponent} from './canva-popup/canva-popup.component';
import {IUploadedFile} from '../../../services/interfaces/IApiMediaUpload';

enum SortType {
  name = 'name',
  fileSize = 'fileSize'
}

enum FilterType {
  all = 'all',
  images = 'images',
  videos = 'videos',
  canva = 'canva'
}

const VIDEO_MIME_TYPES: string[] = ['video/mp4', 'video/quicktime'];

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss', '../device-list/device-list.component.scss']
})
export class MediaLibraryComponent implements OnInit {
  loaded: boolean = false;
  media: IApiMedia[];

  currentUsage: number;
  maxUsage: number;
  percentageStorageFull: number;

  sortType: SortType = SortType.name;
  sortAscending: boolean = true;
  filterType: FilterType = FilterType.all;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private ngZone: NgZone,
    private snackBar: MatSnackBar
  ) { }

  get filteredMedia(): IApiMedia[] {
    let filteredMedia: IApiMedia[] = [];

    for (let media of this.media) {
      switch (this.filterType) {
        case FilterType.all:
          filteredMedia.push(media);
          break;

        case FilterType.images:
          if (!VIDEO_MIME_TYPES.includes(media.mimetype)) {
            filteredMedia.push(media);
          }
          break;

        case FilterType.videos:
          if (VIDEO_MIME_TYPES.includes(media.mimetype)) {
            filteredMedia.push(media);
          }
          break;

        case FilterType.canva:
          if (media.canvaDesignId) {
            filteredMedia.push(media);
          }
          break;
      }
    }

    return filteredMedia;
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData() {
    this.apiService.getInfo().subscribe(info => {
      this.ngZone.run(() => {
        this.loaded = true;

        this.currentUsage = Math.round(info.diskUsage);
        this.maxUsage = (info.subscription as IApiAccountInfoSubscription).data.limitations.storage;
        this.percentageStorageFull = Math.ceil(Math.min(100, (this.currentUsage / this.maxUsage) * 100));
        if (this.media) {
          for (let newMedia of info.media) {
            if (!this.media.find(x => x._id === newMedia._id)) {
              this.media.unshift(newMedia);
            }
          }

          let toRemove: IApiMedia[] = [];
          for (let oldMedia of this.media) {
            if (!info.media.find(x => x._id === oldMedia._id)) {
              toRemove.push(oldMedia);
            }
          }

          for (let old of toRemove) {
            this.media.splice(this.media.indexOf(old), 1);
          }
        } else {
          this.media = info.media;
        }

        this.sortMedia();
      })
    });
  }

  openMediaUpload() {
    if (this.currentUsage >= this.maxUsage) {
      this.snackBar.open('You are at your maximum storage allowance. Consider upgrading your subscription for more.', null, {
        duration: 5000
      });
      return;
    }
    const dialogRef = this.dialog.open(UploadMediaComponent);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.reloadData();
      }
    });
  }

  removeMedia(media: IApiMedia) {
    this.dialog.open(ConfirmDeleteComponent, {
      width: '500px',
      data: { name: media.originalname }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteMedia(media._id).subscribe((data: { status: boolean }) => {
          this.ngZone.run(() => {
            if (data.status) {
              let index = this.media.indexOf(media);
              if (index > -1) {
                this.media.splice(index, 1);
                this.reloadData();

                this.snackBar.open('Media successfully deleted', null, {
                  duration: 3000
                });
              }
            } else {
              this.snackBar.open('Failed to delete media.', null, {
                duration: 3000
              });
            }
          });
        })
      }
    });
  }

  openCanvaCreate(media: IApiMedia = null) {
    this.dialog.open(CanvaPopupComponent, {
      width: '400px',
      data: { media: media },
      disableClose: true,
    }).afterClosed().subscribe((files: IUploadedFile[]) => {
      if (files && media) {
        let index = this.media.indexOf(media);
        this.media[index] = {
          ...this.media[index],
          thumb: files[0].thumb,
          location: files[0].location,
          originalname: files[0].originalname,
          mimetype: files[0].mimetype
        }
      } else {
        this.reloadData();
      }
    });
  }

  setFilter(type: string) {
    this.filterType = type as FilterType;
  }

  setSort(type: string) {
    if (type === this.sortType) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortType = type as SortType;
      this.sortAscending = true;
    }

    this.sortMedia()
  }

  private sortMedia() {
    this.media.sort((a, b) => {
      switch (this.sortType) {
        case SortType.name:
          if (this.sortAscending) {
            return a.originalname > b.originalname ? 1 : -1;
          } else {
            return a.originalname < b.originalname ? 1 : -1;
          }

        case SortType.fileSize:

          if (this.sortAscending) {
            return a.size > b.size ? 1 : -1;
          } else {
            return a.size < b.size ? 1 : -1;
          }
      }

      return 1;
    })
  }
}
