<h3>Design with Canva</h3>
<div *ngIf="loading || saving || (!loading && data && data.media); else selectCanva" class="loading">
  <app-loading-spinner style="font-size: 32px"></app-loading-spinner>
  <h4 *ngIf="saving">Saving...</h4>
</div>

<ng-template #selectCanva>
  <p>Select your media type to get started</p>

  <div class="options">
    <button mat-flat-button color="primary" (click)="designCanva('mp4', 'Video')">Design Video</button>
    <button mat-flat-button color="primary" (click)="designCanva('png', 'LargeRectangleAd')">Design Image</button>
  </div>

  <button class="close" mat-flat-button color="warn" (click)="close()">Cancel</button>
</ng-template>
