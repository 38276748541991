import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

type EmojiPickerData = {
  emoji: string | null
}

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss']
})
export class EmojiPickerComponent implements OnInit, AfterViewInit {
  selectedEmoji: string;
  showPicker: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EmojiPickerComponent>,
    @Inject(MAT_DIALOG_DATA) data: EmojiPickerData
  ) {
    if (data.emoji) {
      this.selectedEmoji = data.emoji;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.showPicker = true;
  }

  onEmojiSelect(event) {
    let number = parseInt(event.emoji.unified, 16);
    this.selectedEmoji = String.fromCodePoint(number);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.selectedEmoji);
  }
}
