<h3>Emoji Picker</h3>
<div class="current-emoji">
  Currently selected Emoji
  <ng-container *ngIf="selectedEmoji; else noEmoji"><div class="emoji">{{ selectedEmoji }}</div></ng-container>
  <ng-template #noEmoji><strong>No Emoji Selected</strong></ng-template>
</div>

<emoji-mart *ngIf="showPicker" title="Pick your emoji…" emoji="point_up" (emojiSelect)="onEmojiSelect($event)" (emojiClick)="onEmojiSelect($event)" [darkMode]="false" [skin]="1"></emoji-mart>

<div class="buttons">
  <button mat-flat-button color="warn" (click)="close()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()">Save Selection</button>
</div>
