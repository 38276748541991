import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/account/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './components/account/register/register.component';
import {DashboardModule} from './components/dashboard/dashboard.module';
import {AuthService} from './services/auth.service';
import {authInterceptorProviders} from './helpers/authInterceptor';
import { LogoutComponent } from './components/account/logout/logout.component';
import { SettingsComponent } from './components/account/settings/settings.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { ForgotPasswordComponent } from './components/account/forgot-password/forgot-password.component';
import {RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module} from 'ng-recaptcha';
import { KamarAuthComponent } from './components/account/settings/kamar-auth/kamar-auth.component';
import { NgxHotjarModule } from 'ngx-hotjar';
import {enableProdMode} from '@angular/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { CanvaPopupComponent } from './components/dashboard/media-library/canva-popup/canva-popup.component';
import {BurgerService} from './services/burger.service';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


Sentry.init({
  dsn: "https://daa69c0ea6314db28fa473fb07e6433a@o551773.ingest.sentry.io/5675522",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://dashboard.pomp.co.nz"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    LogoutComponent,
    SettingsComponent,
    ForgotPasswordComponent,
    KamarAuthComponent,
    CanvaPopupComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        DashboardModule,
        ColorPickerModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        RecaptchaV3Module,
        RecaptchaModule,
        NgxMatSelectSearchModule,
        NgxHotjarModule.forRoot('2296807'),
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSlideToggleModule
    ],
  providers: [
    AuthService,
    authInterceptorProviders,
    BurgerService
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeXFMsSAAAAAGo08vWK4EIAX4n10lDHLSYFOTI0' },
    // { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LeXFMsSAAAAAGo08vWK4EIAX4n10lDHLSYFOTI0' } } // 6LcUslwaAAAAAPiFFXHTQYA0Uq3U4F9r2saQm55W
  ],
  exports: [
    MatCardModule,
    MatButtonModule,
    MatInputModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
