<div class="container">
  <mat-card class="loading-card" *ngIf="!loaded">
    <app-loading-spinner></app-loading-spinner>
  </mat-card>



  <div class="top" *ngIf="loaded && devices.length !== 0">
    <h1 >Your Devices</h1>
    <button mat-flat-button color="primary" (click)="showRegister()" *ngIf="loaded">Register New Device</button>
  </div>


  <div class="no-devices" *ngIf="loaded && devices.length == 0">

    <img src="/assets/images/computer-graphic.svg">

      <h1>You have no registered devices.</h1>
      <div class="how-to">You need to register a device to start using POMP. Please download the Apple TV or Android TV app. To link your device click the register new device button below.</div>

      <button mat-flat-button color="primary" (click)="showRegister()" *ngIf="loaded">Register New Device</button>

      <div class="app-buttons">
        <a href="https://apps.apple.com/app/pomp/id1539266218" target="_blank"><img src="assets/images/apple-tv.svg" draggable="false"></a>
        <a href="https://play.google.com/store/apps/details?id=nz.co.pomp.pompreact" target="_BLANK"><img src="assets/images/google_badge.png" draggable="false"></a>
      </div>
  </div>

  <div *ngIf="loaded" class="devices">
    <mat-card *ngFor="let device of devices" [ngClass]="{'device-disabled': device.disabled === true, 'device-card': true}">
        <div class="device-details">
          <div class="header">
            <mat-card-title class="title">{{ device.nickName }}</mat-card-title>
            <div class="last-usage"><span [ngClass]="{ ok: getLastUsage(device.lastPing).includes('Connected'), offline: !getLastUsage(device.lastPing).includes('Connected') }">{{ getLastUsage(device.lastPing) }}</span></div>

          </div>
          <mat-error *ngIf="device.disabled == true">This device has been disabled due to a plan error - <a routerLink="/dashboard/subscriptions" color="green" *ngIf="device.disabled == true">Change Plan</a>
          </mat-error>

          <div class="currently-playing" *ngIf="getPlaylistForDevice(device) !== null">Currently Playing Playlist {{ getPlaylistForDevice(device).name }}</div>
        </div>

      <div class="playlist-selector">
        <mat-form-field>
          <mat-label>Device Name</mat-label>
          <input matInput [formControl]="deviceFormControls[device._id]">
          <mat-hint>A display name for your device</mat-hint>
          <mat-error *ngIf="deviceFormControls[device._id].hasError('duplicate')">Your device nick name must be unique</mat-error>
          <mat-error *ngIf="deviceFormControls[device._id].invalid && !deviceFormControls[device._id].hasError('duplicate')">The name must be at least 3 characters</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Playlist</mat-label>
          <mat-select [(ngModel)]="device.playlist" (valueChange)="onDataChanged(device)">
            <mat-option [value]="playlist._id" *ngFor="let playlist of playlists">{{ playlist.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Orientation</mat-label>
          <mat-select [(ngModel)]="device.orientation" (valueChange)="onDataChanged(device)">
            <mat-option value="portrait">Portrait</mat-option>
            <mat-option value="landscape">Landscape</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="buttons">
        <button mat-flat-button color="warn" (click)="removeDevice(device)">Delete Device</button>
        <a mat-flat-button routerLink="/dashboard/subscriptions" color="green" *ngIf="device.disabled == true">Change Plan</a>
        <button mat-flat-button color="primary" *ngIf="device.dataChanged" (click)="updateDevice(device)" [disabled]="!deviceFormControls[device._id].pristine && deviceFormControls[device._id].invalid">Update Device Data</button>
      </div>
    </mat-card>
  </div>
</div>
