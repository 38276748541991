<div class="container">
  <div class="content login">
    <mat-card class="animated">
      <mat-card-title>Create a new account</mat-card-title>
      <div class="awaiting-verify" *ngIf="awaitingVerify">
        <h3>Verify your email address!</h3>
        <p>Check your emails and verify your address before logging into your account.</p>
      </div>
      <form [formGroup]="form" (submit)="attemptLogin()" *ngIf="!awaitingVerify">
        <mat-form-field appearance="standard">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email Address" formControlName="email">
          <mat-error *ngIf="email.hasError('required')">Your email is <strong>required</strong></mat-error>
          <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">Please enter a valid email address</mat-error>
          <mat-error *ngIf="email.hasError('duplicate') && !email.hasError('required')">This email address is already in use!</mat-error>
        </mat-form-field>
        <div [formGroup]="passwords" class="passwordfields">
          <mat-form-field appearance="standard"  class="width-half">
            <mat-label>Password</mat-label>
            <input type="password" matInput placeholder="Password" formControlName="password">
            <mat-error *ngIf="passwords.controls.password.hasError('required')">Your password is <strong>required</strong></mat-error>
          </mat-form-field>
          <mat-form-field appearance="standard" class="width-half">
            <mat-label>Retype Password</mat-label>
            <input type="password" matInput placeholder="Confirm your Password" formControlName="retypePassword">
            <mat-error *ngIf="passwords.controls.retypePassword.invalid">You <strong>must</strong> retype your password</mat-error>
            <mat-error *ngIf="!passwords.controls.retypePassword.invalid && passwords.invalid">Your retyped password does not match</mat-error>
          </mat-form-field>
        </div>

        <button type="submit" mat-raised-button color="primary" [disabled]="form.pristine">Sign Up</button>
      </form>
    </mat-card>
    <a [routerLink]="['../', 'login']" class="loginAccountLink">Already got an account? Login</a>
  </div>
</div>
