import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RecaptchaComponent, ReCaptchaV3Service} from 'ng-recaptcha';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private data: { email: string, password: string };

  form: FormGroup;

  captchaSiteKey: string = '6Lf5fW0aAAAAAMPbbzojQ4nN1f23ffY9MUDEt_BT';
  private captchaToken: string;

  @ViewChild('captchaRef')
  private captchaRef: RecaptchaComponent;

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  ngOnInit(): void {
    this.data = { email: '', password: ''};
    this.form = new FormGroup({
      email: new FormControl(this.data.email, [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl(this.data.password, Validators.required)
    });
  }


  onCaptchaResolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
    console.log(captchaResponse)

    this.attemptLogin();

  }

  onCaptchaError(event) {
    console.log(event)
  }


  attemptLogin() {
    if (!this.captchaToken) {
      // request captcha here.
      this.captchaRef.execute();
      return;
    }

    if (this.email.invalid || this.password.invalid) {
      return;
    }

    this.authService.login({ email: this.email.value, password: this.password.value, captchaToken: this.captchaToken }).subscribe(this.onData.bind(this));
  }

  onData(data: { success: boolean, response?: string, token?: string }) {
    if (data.success && data.token) {
      this.authService.setToken(data.token);
      this.router.navigate(['/dashboard']);
    } else {
        this.snackBar.open(data.response, null, {
          duration: 3000,
          panelClass: 'snackbar-error'
        });
    }
  }

}
