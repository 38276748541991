import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/account/login/login.component';
import { RegisterComponent } from './components/account/register/register.component';
import {AuthGuard} from './guards/auth.guard';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {DeviceListComponent} from './components/dashboard/device-list/device-list.component';
import {GuestGuard} from './guards/guest.guard';
import {LogoutComponent} from './components/account/logout/logout.component';
import {RegisterDeviceComponent} from './components/dashboard/device-list/register-device/register-device.component';
import {SettingsComponent} from './components/account/settings/settings.component';
import {PlaylistListComponent} from './components/dashboard/playlist-list/playlist-list.component';
import {PlaylistComponent} from './components/dashboard/playlist-list/playlist/playlist.component';
import {DeviceComponent} from './components/dashboard/device/device.component';
import {MediaLibraryComponent} from './components/dashboard/media-library/media-library.component';
import {PaymentComponent} from './components/dashboard/payment/payment.component';
import {ForgotPasswordComponent} from './components/account/forgot-password/forgot-password.component';
import {ChooseSubscriptionComponent} from './components/dashboard/choose-subscription/choose-subscription.component';

const routes: Routes = [
  {
    path: 'account',
    children: [
      {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [AuthGuard],
        data: { title: 'Logged Out' }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Account Settings' },
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [GuestGuard],
        data: { title: 'Log In' }
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [GuestGuard],
        data: { title: 'Create an Account' }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [GuestGuard],
        data: {title: 'Forgot Password'  }
      }
    ]
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: 'devices',
        component: DeviceListComponent,
        data: { title: 'Your Devices' },
      },
      {
        path: 'devices/register',
        component: RegisterDeviceComponent,
        data: { title: 'Register Device' }
      },
      {
        path: 'devices/:id',
        component: DeviceComponent,
        data: { title: 'Your Device' }
      },
      {
        path: 'playlists/:id',
        component: PlaylistComponent,
        data: { title: 'Manage Playlist' }
      },
      {
        path: 'playlists',
        component: PlaylistListComponent,
        data: { title: 'Your Playlists' }
      },
      {
        path: 'media',
        component: MediaLibraryComponent,
        data: { title: 'Your Media' }
      },
      {
        path: 'payment',
        component: PaymentComponent,
        data: { title: 'Payment Portal' }
      },
      {
        path: 'subscriptions',
        component: ChooseSubscriptionComponent,
        data: { title: 'Manage Subscription' }
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'devices'
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
