import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface IConfirmDeleteData {
  name: string;
}

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  deleteAgreed: boolean = false;


  constructor(
    private dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDeleteData
  ) { }

  ngOnInit(): void {
  }

  cancelDelete() {
    this.dialogRef.close(false);
  }

  confirmDelete() {
    this.dialogRef.close(true);
  }

}
