import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';

interface KamarAuthData {
  username: string;
  password: string;
  api: string;
}

@Component({
  selector: 'app-kamar-auth',
  templateUrl: './kamar-auth.component.html',
  styleUrls: ['./kamar-auth.component.scss']
})
export class KamarAuthComponent implements OnInit {
  public regeningKamar: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KamarAuthData,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<KamarAuthComponent>
  ) { }

  ngOnInit(): void {
  }

  onRegenClick() {
    if (this.regeningKamar) {
      return;
    }

    this.regeningKamar = true;
    this.apiService.kamarRegen().subscribe(result => {
      this.regeningKamar = false;
      this.data.username = result.username;
      this.data.password = result.password

      this.snackbar.open('KAMAR credentials updated', null, {
        duration: 3000
      });
    })
  }

  copyToClipboard(value: string) {
    let tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.snackbar.open('Successfully copied to clipboard', null, {
      duration: 5000
    });
  }

  close() {
    this.dialogRef.close(null);
  }
}
