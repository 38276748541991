<ng-container *ngIf="loading; else window">
  <div class="loading">
    <app-loading-spinner></app-loading-spinner>
    <h3>Loading Content...</h3>
  </div>
</ng-container>

<ng-template>
  <mat-horizontal-stepper linear>
    <mat-step [editable]="false" [completed]="false">
      <ng-template matStepLabel>Select a Screen</ng-template>
<!--      <ng-container [ngTemplateOutlet]="screenLibrary"></ng-container>-->
    </mat-step>
    <mat-step [editable]="false" [completed]="false">
      <ng-template matStepLabel>Configure Screen Options</ng-template>
      <h2>Screen Configuration</h2>
    </mat-step>

  </mat-horizontal-stepper>
</ng-template>

<ng-template #window>
  <h2>Screen Library</h2>
  <div class="header">
    <div class="helper">Scroll down for more screens.</div>
    <!-- <div class="tags">
      <mat-chip-list>
        <mat-chip>Tag 1</mat-chip>
        <mat-chip>Tag 2</mat-chip>
        <mat-chip>Tag 3</mat-chip>
        <mat-chip>Tag 4</mat-chip>
        <mat-chip>Tag 5</mat-chip>
        <mat-chip>Tag 6</mat-chip>
        <mat-chip color="accent" selected>Tag 7</mat-chip>
      </mat-chip-list>
    </div> -->

    <div class="count">{{ screenTypes.length }} Screens</div>
  </div>

  <mat-dialog-content>
    <div class="screens">

      <div class="media-box full" *ngFor="let screen of screenTypes">
        <div class="mediaItem" style="background-image:url({{screen.image}});">
          <div class="premium" *ngIf="screen.premium">Premium</div>
        </div>

        <!-- <img [src]="media.thumb ? media.thumb : media.location" class="media-element"> -->
        <div class="top visible">
          <div class="name">{{ screen.name }}</div>

          <div class="btns">
            <span (click)="addToPlaylist(screen)" class="btn">
            Add to Playlist
            </span>
          </div>
        </div>
      </div>
      
      
      <!-- <div class="screen" *ngFor="let screen of screenTypes">
        <img [src]="screen.image" alt="Screen Example">
        <div class="name">{{ screen.name }}</div>
        <div class="options">
          <button mat-flat-button (click)="displayMoreInfo(screen)">More Info</button>
          <button mat-flat-button color="primary" (click)="addToPlaylist(screen)">Add to Playlist</button>
        </div>
      </div> -->
    </div>
  </mat-dialog-content>
  <div class="bottom">
    <button mat-flat-button color="warn" (click)="close()">Close</button>
  </div>
</ng-template>

<ng-template #customisationSection>

</ng-template>


<ng-template #moreInfo>
  <div class="more-info">
    <div class="top">
      <h3>{{ moreInfoScreen.name }}</h3>
      <button mat-flat-button (click)="closeMoreInfo()">Close</button>
    </div>
    <mat-dialog-content>
      <p>{{ moreInfoScreen.description }}</p>
      <ng-container *ngIf="moreInfoScreen.properties.length > 0">
        <h4>Required Configuration</h4>
        <ul>
          <li *ngFor="let property of moreInfoScreen.properties">{{ property.name }}</li>
        </ul>
      </ng-container>
      <h4>Screen Previews</h4>
      <img [src]="moreInfoScreen.image" alt="Screen Example">
    </mat-dialog-content>
  </div>
</ng-template>
