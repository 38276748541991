<div class="container">
  <div class="content login">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Request a password reset
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Fill out your email and complete the captcha to request a password reset.</p>
        <mat-form-field class="w-100">
          <mat-label>Email Address</mat-label>
          <input matInput [formControl]="formControl">
          <mat-error *ngIf="formControl.invalid">A valid email address is required.</mat-error>
          <mat-hint>Enter the email you signed up with</mat-hint>
        </mat-form-field>
<!--        <re-captcha #captchaRef (resolved)="onCaptchaResolved($event)" (error)="onCaptchaError($event)" errorMode="handled" size="invisible"></re-captcha>-->
        <re-captcha
          #captchaRef="reCaptcha"
          (resolved)="onCaptchaResolved($event)"
          (error)="onCaptchaError($event)"
          errorMode="handled"
          [siteKey]="captchaSiteKey"
          size="invisible"
        ></re-captcha>
        <button mat-flat-button color="primary" class="requestForgotPassword" (click)="submitRequest()">Request Password Reset</button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
