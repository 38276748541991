import {Component, Inject, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {IApiCurrencyType, IApiScreenProperty, IApiScreenType} from '../../../../../services/interfaces/IApiConfig';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../../services/api.service';
import {IApiPlaylistScreen, IDateBasedCondition, IDayBasedCondition} from '../../../../../services/interfaces/IApiPlaylist';
import {MediaSelectorComponent} from '../../../media-library/media-selector/media-selector.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import {EmojiPickerComponent} from '../../../util/emoji-picker/emoji-picker.component';
import { IApiAccountInfoSubscription } from 'src/app/services/interfaces/IApiAccountInfo';

type ScreenConfigurationData = {
  screenType: IApiScreenType,
  screen?: IApiPlaylistScreen
};

type SomeCondition = IDayBasedCondition | IDateBasedCondition;

@Component({
  selector: 'app-screen-configurator',
  templateUrl: './screen-configurator.component.html',
  styleUrls: ['./screen-configurator.component.scss']
})
export class ScreenConfiguratorComponent implements OnInit {
  screen: IApiPlaylistScreen;
  screenType: IApiScreenType;

  currencyTypes: ReplaySubject<IApiCurrencyType[]> = new ReplaySubject<IApiCurrencyType[]>(1);
  currencyTypesMaster: IApiCurrencyType[];
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  canPickPremium: boolean;

  formGroup: FormGroup;
  conditions: SomeCondition[];
  loading: boolean;

  formControlTypes = [ 'string', 'currencySelector', 'enum', 'enumMulti', 'hailOrganisations', 'googleCalendars'];

  // do not edit directly - use showWarningMessage function
  warningMessage = '';

/*  metadata: IScreenMetadata;
  type: string;
  duration: number;
  conditions: {
    dateBased: IDateBasedCondition[];
    dayBased: IDayBasedCondition[];
  }*/
  constructor(
    private dialogRef: MatDialogRef<ScreenConfiguratorComponent>,
    @Inject(MAT_DIALOG_DATA) data: ScreenConfigurationData,
    private dialog: MatDialog,
    private apiService: ApiService


  ) {
    this.screenType = data.screenType;

    if (!data.screen) {
      this.screen = {
        type: data.screenType.id,
        duration: 30,
        conditions: {
          dateBased: [],
          dayBased: []
        },
        metadata: {}
      };
    } else {
      this.screen = JSON.parse(JSON.stringify(data.screen));
    }


    for (let condition of this.screen.conditions.dateBased) {
      if (typeof condition.start === 'string') {
        condition.start = new Date(condition.start);
      }
      if (typeof condition.end === 'string') {
        condition.end = new Date(condition.end);
      }
    }

    for (let condition of this.screen.conditions.dayBased) {
      let start = new Date();
      let end = new Date();

      start.setHours(condition.startHour, condition.startMinute, 0, 0);
      end.setHours(condition.endHour, condition.endMinute, 0, 0);

      condition.start = start;
      condition.end = end;
    }

    this.conditions = [...this.screen.conditions.dayBased, ...this.screen.conditions.dateBased];
  }

  ngOnInit(): void {
    let controls: { [key: string]: FormControl } = {}

    let loaded = 0;
    const onLoad = () => {
      if (loaded >= 2) { // CHANGE THIS IF YOU ADD MORE API REQS
        this.loading = false;
      }
    };

    this.apiService.getInfo().subscribe(result => {
      this.canPickPremium = (result.subscription as IApiAccountInfoSubscription).data.limitations.premiumIntegrations;
      let orgs = this.screenType.properties.find(x => x.type === 'hailOrganisations');
      if (orgs) {
        if (result.hail && result.hail.active) {
          orgs.options = result.hail.organisations;
        } else {
          this.showWarningMessage('You have not configured Hail. You will not be able to add this screen to your playlist. You can connect your Hail account on the account settings page.');
        }
      }

      let googleCalendars = this.screenType.properties.find(x => x.type === 'googleCalendars');
      if (googleCalendars) {
        if (result.google.active) {
          googleCalendars.options = result.google.calendars;
        } else {
          this.showWarningMessage('You have not configured Google. You will not be able to add this screen to your playlist. You can connect your Google account on the account settings page.');
        }
      }

      onLoad();
    });

    this.apiService.getConfig().subscribe(result => {
      this.currencyTypes.next(result.currencyTypes.slice());
      this.currencyTypesMaster = result.currencyTypes;

      onLoad();
    });

    for (let prop of this.screenType.properties) {
      if (!this.formControlTypes.includes(prop.type)) {
        continue;
      }

      let validators = [];

      if (prop.required) {
        validators.push(Validators.required);
      }

      if (prop.validation) {
        if (prop.validation.minLength) {
          validators.push(Validators.minLength(prop.validation.minLength));
        }
        if (prop.validation.maxLength) {
          validators.push(Validators.minLength(prop.validation.maxLength));
        }
        if (prop.validation.regex) {
          validators.push(Validators.pattern(prop.validation.regex));
        }
      }

      controls[prop.key] = new FormControl(this.screen.metadata[prop.key] ?? '', validators);
    }

    this.formGroup = new FormGroup(controls)
  }

  selectMedia(screen: IApiPlaylistScreen, type: string) {
    let selected: string[] = [];
    switch (type) {
      case 'images':
        selected = screen.metadata.imagesURLs ?? [];
        break;

      case 'videos':
        selected = screen.metadata.videosURLs ?? [];
        break;
    }
    const dialogRef = this.dialog.open(MediaSelectorComponent, {
      width: '800px',
      data: { screen: screen, type: type, selected: selected },
    });

    dialogRef.afterClosed().subscribe(updated => {
      if (updated) {
        // idk maybe save to server here?
      }
    })
  }

  isRecurringCondition(condition: SomeCondition): boolean {
    return condition && condition.hasOwnProperty('dayName');
  }

  changeConditionType(condition: SomeCondition) {
    let newCondition: SomeCondition;
    if (this.isRecurringCondition(condition)) {
      let date = new Date();
      date.setSeconds(0, 0);
      newCondition = {
        start: date,
        end: date,
      };
    } else {
      let start = new Date();
      let end = new Date();
      start.setHours(0, 0, 0, 0);
      end.setHours(1, 0, 0, 0);
      newCondition = {
        dayName: 'Monday',
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0,
        start: start,
        end: end
      };
    }

    let index = this.conditions.indexOf(condition);
    this.conditions[index] = newCondition;
  }

  addCondition() {
    let date = new Date();
    date.setSeconds(0, 0);
    let data: IDateBasedCondition = {
      start: date,
      end: date
    }

    this.conditions.push(data);
  }

  removeCondition(condition: SomeCondition) {
    if (!this.conditions.includes(condition)) {
      return;
    }

    this.conditions.splice(this.conditions.indexOf(condition), 1);
  }

  openEmojiSelector(data: IApiScreenProperty) {
    this.dialog.open(EmojiPickerComponent, {
      maxWidth: '300px',
      data: {
        emoji: this.screen.metadata.hasOwnProperty(data.key) ? this.screen.metadata[data.key] : null
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        console.log(result, this.screen.metadata)
        this.screen.metadata[data.key] = result;
      }
    })
  }

  showWarningMessage(message: string) {
    if (this.warningMessage) {
      return;
    }

    this.warningMessage = message;
  }

  save() {
    if (this.formGroup.invalid) {
      let controls = Object.values(this.formGroup.controls);
      for (let control of controls) {
        control.markAsTouched();
      }

      return;
    }

    this.screen.conditions = {
      dateBased: [],
      dayBased: []
    };

    for (let condition of this.conditions) {
      if (this.isRecurringCondition(condition)) {
        let c = condition as IDayBasedCondition;
        c.startHour = c.start.getHours();
        c.startMinute = c.start.getMinutes();
        c.endHour = c.end.getHours();
        c.endMinute = c.end.getMinutes();
        this.screen.conditions.dayBased.push(c);
      } else {
        this.screen.conditions.dateBased.push(condition as IDateBasedCondition);
      }
    }

    for (let key in this.formGroup.controls) {
      if (!this.formGroup.controls.hasOwnProperty(key)) {
        continue;
      }

      let props = this.screenType.properties.find(x => x.key === key);
      if (!props) {
        continue;
      }

      if (this.formControlTypes.includes(props.type)) {
        this.screen.metadata[key] = this.formGroup.controls[key].value;
      }
    }

    this.dialogRef.close(this.screen);
  }

  close() {
    this.dialogRef.close();
  }
}
