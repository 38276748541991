import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LoadingSpinnerComponent} from '../util/loading-spinner/loading-spinner.component';
import {Subscriber, Subscription} from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {

  private $paymentPortal: Subscription;
  private dialogRef: MatDialogRef<LoadingSpinnerComponent>;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.$paymentPortal = this.apiService.getPaymentPortalUrl().subscribe(result => {
      window.location.href = result.url;
    });

    this.dialogRef = this.dialog.open(LoadingSpinnerComponent, {
      disableClose: true
    })
  }

  ngOnDestroy() {
    this.dialogRef.close();
    this.$paymentPortal.unsubscribe();
  }

}
