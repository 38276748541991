import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IApiClaimDeviceResult} from './interfaces/IApiClaimDeviceResult';
import {IApiAccountInfo} from './interfaces/IApiAccountInfo';
import {share} from 'rxjs/operators';
import {IApiCreatePlaylist} from './interfaces/IApiCreatePlaylist';
import {IApiPlaylist} from './interfaces/IApiPlaylist';
import {IApiPortalUrl} from './interfaces/IApiPortalUrl';
import {IApiStripeSession} from './interfaces/IApiStripeSession';
import {IApiSubscription} from './interfaces/IApiSubscription';
import {IApiSpotifyConnect} from './interfaces/IApiSpotifyConnect';
import {IApiHailConnect} from './interfaces/IApiHailConnect';
import {IApiConfig} from './interfaces/IApiConfig';
import {IApiKamarAuth} from './interfaces/IApiKamarAuth';
import {IApiMediaUpload} from './interfaces/IApiMediaUpload';
import {IApiCanvaResponse} from './interfaces/IApiCanvaResponse';

const API = 'https://api.pomp.co.nz';
const postOptions = {
  headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _getInfo$: Observable<IApiAccountInfo>;

  constructor(
    private httpClient: HttpClient
  ) { }

  claimDevice(code: string): Observable<IApiClaimDeviceResult> {
    const formData = new URLSearchParams();
    formData.set('code', code);
    return this.httpClient.post<IApiClaimDeviceResult>(`${API}/account/claim`, formData.toString(), postOptions);
  }

  saveDevice(id: string, playlistId: string, name: string, orientation: string): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('id', id);
    formData.set('playlistid', playlistId);
    formData.set('name', name);
    formData.set('orientation', orientation);

    return this.httpClient.post(`${API}/receive/device/save`, formData.toString(), postOptions);
  }

  deleteDevice(id: string): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('id', id);

    return this.httpClient.post(`${API}/receive/device/delete`, formData.toString(), postOptions);
  }

  createPlaylist(name: string): Observable<IApiCreatePlaylist> {
    const formData = new URLSearchParams();
    formData.set('name', name);
    return this.httpClient.post<IApiCreatePlaylist>(`${API}/receive/playlist/create`, formData.toString(), postOptions);
  }

  savePlaylist(playlist: IApiPlaylist): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('id', playlist._id);
    formData.set('name', playlist.name);
    formData.set('transitionType', playlist.transitionType);
    formData.set('tickerEnabled', String(playlist.ticker.enabled));
    formData.set('tickerText', playlist.ticker.text);
    formData.set('screens', JSON.stringify(playlist.screens));
    return this.httpClient.post(`${API}/receive/playlist/save`, formData.toString(), postOptions);
  }

  deletePlaylist(id: string): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('id', id);

    return this.httpClient.post(`${API}/receive/playlist/delete`, formData.toString(), postOptions);
  }

  getInfo(): Observable<IApiAccountInfo> {
    if (!this._getInfo$) {
      this._getInfo$ = this.httpClient.get<IApiAccountInfo>(`${API}/account/info`).pipe(share());
    }
    return this._getInfo$;
  }

  updateAccountSettings(currentPassword: string, newPassword: string, newEmail: string, sendOfflineAlerts: boolean): Observable<any> {
    const formData = new URLSearchParams();
    if (newPassword) {
      formData.set('password', newPassword);
    }
    if (newEmail) {
      formData.set('email', newEmail);
    }

    formData.set('sendOfflineAlerts', sendOfflineAlerts ? 'true' : 'false');

    return this.httpClient.post(`${API}/account/change-settings`, formData.toString(), postOptions);
  }

  updateAccountCustomisation(colour1: string, colour2: string, logo: string, backgroundStyle: string, bgimage: string): Observable<any> {
    const formData = new URLSearchParams();
    if (colour1) {
      formData.set('brandColour1', colour1);
    }

    if (colour2) {
      formData.set('brandColour2', colour2);
    }

    if (logo) {
      formData.set('logo', logo);
    }

    if (bgimage) {
      formData.set('bgimage', bgimage);
    }

    if (backgroundStyle) {
      formData.set('backgroundStyle', backgroundStyle);
    }

    return this.httpClient.post(`${API}/account/change-settings`, formData.toString(), postOptions);
  }

  getSpotifyAuthLogin(): Observable<IApiSpotifyConnect> {
    return this.httpClient.post<IApiSpotifyConnect>(`${API}/account/spotify/connect`, {});
  }

  getHailAuthLogin(): Observable<IApiHailConnect> {
    return this.httpClient.post<IApiHailConnect>(`${API}/account/hail/connect`, {});
  }

  getGoogleAuthLogin(): Observable<IApiHailConnect> {
    return this.httpClient.post<IApiHailConnect>(`${API}/account/google/connect`, {});
  }

  uploadMedia(files: File[]): Observable<HttpEvent<any>> {
    const formData = new FormData();
    files.forEach(file => formData.append('photos', file));

    const req = new HttpRequest('POST', `${API}/upload/media`, formData, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.httpClient.request(req);
  }


  deleteMedia(id: string): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('id', id);

    return this.httpClient.post(`${API}/receive/media/delete`, formData.toString(), postOptions);
  }

  getPaymentPortalUrl(): Observable<IApiPortalUrl> {
    return this.httpClient.post<IApiPortalUrl>(`${API}/stripe/portal`, '');
  }


  canvaDesignPublish(designId: string, exportUrl: string, designTitle: string, fileType: string): Observable<IApiCanvaResponse> {
    const formData = new URLSearchParams();
    formData.set('designId', designId);
    formData.set('exportUrl', exportUrl);
    formData.set('designTitle', designTitle);
    formData.set('fileType', fileType);

    return this.httpClient.post<IApiCanvaResponse>(`${API}/upload/canva`, { designId: designId, exportUrl: exportUrl, designTitle: designTitle, fileType: fileType });
  }


  createCheckoutSession(planId: string): Observable<IApiStripeSession> {
    const formData = new URLSearchParams();
    formData.set('planId', planId);

    // return this.httpClient.post<IApiStripeSession>(`${API}/stripe/create-checkout-session`, formData.toString(), postOptions);
    return this.httpClient.post<IApiStripeSession>(`${API}/stripe/create-checkout-session`, { planId: planId });
  }

  changeSubscription(planId: string): Observable<any> {
    const formData = new URLSearchParams();
    formData.set('planId', planId);

    return this.httpClient.post(`${API}/stripe/change-subscription`, formData.toString(), postOptions);
  }

  getSubscriptionPlans(): Observable<IApiSubscription[]> {
    return this.httpClient.get<IApiSubscription[]>(`${API}/distribute/plans`);
  }

  getConfig(): Observable<IApiConfig> {
    return this.httpClient.get<IApiConfig>(`${API}/distribute/config`);
  }

  kamarRegen(): Observable<IApiKamarAuth> {
    return this.httpClient.post<IApiKamarAuth>(`${API}/account/kamar-regen`, null);
  }
}
