import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {RecaptchaComponent, ReCaptchaV3Service} from 'ng-recaptcha';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login/login.component.scss','./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  formControl: FormControl;
  captchaSiteKey: string = '6Lf5fW0aAAAAAMPbbzojQ4nN1f23ffY9MUDEt_BT';
  private captchaToken: string;

  @ViewChild('captchaRef')
  private captchaRef: RecaptchaComponent;

  constructor(
    // private recaptchaService: ReCaptchaV3Service,
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.formControl = new FormControl('', [
      Validators.required,
      Validators.email
    ]);
  }

  ngOnDestroy() {
  }

  onCaptchaResolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
    console.log(captchaResponse)

    this.submitRequest();
  }

  onCaptchaError(event) {
    console.log(event)
  }

  submitRequest() {
    if (!this.captchaToken) {
      // request captcha here.
      this.captchaRef.execute();
      return;
    }

    if (this.formControl.invalid) {
      this.snackbar.open(`Please provide a valid email address.`, null, {
        duration: 3000
      })
      return;
    }

    this.authService.requestPasswordReset(this.formControl.value, this.captchaToken).subscribe(result => {
      // if (!result.success) {
      this.snackbar.open(result.response, null, {
        duration: 3000
      })
      // }
    });
  }
}
