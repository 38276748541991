import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {IApiDevice} from '../../../services/interfaces/IApiDevice';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  public device: IApiDevice;

  public nickname: string = '';
  public orientation: string = '';
  public successMessage: string;
  public errorMessage: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.apiService.getInfo().subscribe(data => {
        let device = data.devices.find(x => x._id === params.get('id'));
        if (device) {
          this.device = device;

          this.nickname = this.device.nickName;
          this.orientation = this.device.orientation;
        } else {
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
    });
    this.apiService.getInfo().subscribe(data => {

    })
  }

  updateDevice() {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.nickname.trim().length < 3) {
      this.errorMessage = 'Your device nickname must be at least 3 characters!';
      return;
    }

    this.apiService.saveDevice(this.device._id, '', this.nickname, this.orientation).subscribe(response => {
    });
  }

}
