import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {MediaSelectorComponent} from '../../dashboard/media-library/media-selector/media-selector.component';
import {IApiMedia} from '../../../services/interfaces/IApiMedia';
import {IApiAccountInfo, IApiAccountInfoSubscription} from '../../../services/interfaces/IApiAccountInfo';
import {MatSnackBar} from '@angular/material/snack-bar';
import {KamarAuthComponent} from './kamar-auth/kamar-auth.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['../login/login.component.scss', './settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public loaded: boolean = false;
  public updating: boolean = false;

  public currentPassword: string = '';

  public newPassword: string = '';
  public newPasswordRetype: string = '';

  public newEmail: string = '';

  public brandColours: {
    colour1: string;
    colour2: string;
  }

  public logo: string;
  public bgimage: string;

  public spotifyLoading: boolean = false;
  public linkedSpotify: boolean = false;

  public hailLoading: boolean = false;
  public linkedHail: boolean = false;

  public googleLoading: boolean = false;
  public linkedGoogle: boolean = false;
  public sendOfflineAlerts: boolean = false;

  public hasSub: boolean = false;

  public backgroundStyle: string = '';

  public billingInfo: {
    planName: string;
    currentDevices: number;
    maxDevices: number;
    currentPlaylists: number;
    maxPlaylists: number;
    currentStorage: number;
    maxStorage: number;
    premiumIntegrations: boolean;
  }

  private _currentInfo: IApiAccountInfo;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.apiService.getInfo().subscribe(data => {
      this._currentInfo = data;
      this.logo = data.logo;
      this.bgimage = data.bgImage;

      this.brandColours = {
        colour1: data.brandColour1 ?? '#ffffff',
        colour2: data.brandColour2 ?? '#ffffff'
      }
      this.linkedHail = data.hail.active !== false;
      this.linkedSpotify = data.spotify !== false;
      this.linkedGoogle = data.google.active !== false;

      this.loaded = true;

      this.hasSub = data.subscription.planId !== false;

      if (this.hasSub) {
        let subInfo = data.subscription as IApiAccountInfoSubscription;
        this.billingInfo = {
          planName: subInfo.data.name,
          currentDevices: data.devices.length,
          currentPlaylists: data.playlists.length,
          currentStorage: data.diskUsage,
          maxDevices: subInfo.data.limitations.devices,
          maxPlaylists: subInfo.data.limitations.playlists,
          maxStorage: subInfo.data.limitations.storage,
          premiumIntegrations: subInfo.data.limitations.premiumIntegrations
        };
      }

      this.backgroundStyle = data.backgroundStyle;
      this.sendOfflineAlerts = data.sendOfflineAlerts;
    });
  }

  get logoThumbnail(): string {
    return this._currentInfo?.media.find(x => x.location === this.logo)?.thumb ?? this.logo;
  }

  get bgImageThumbnail(): string {
    return this._currentInfo?.media.find(x => x.location === this.bgimage)?.thumb ?? this.bgimage;
  }

  updateSettings() {
    if (!this.currentPassword.trim()) {
      this.snackbar.open('You must enter your current password.', null, {
        duration: 4000
      });
      return;
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.newEmail) {
      if (!emailRegex.test(this.newEmail)) {
        this.snackbar.open('Please enter a valid Email address.', null, {
          duration: 4000
        });
        return;
      }
    }

    if (this.newPassword || this.newPasswordRetype) {
      if (!this.newPassword) {
        this.snackbar.open('You must enter your new password as well as the retyped version', null, {
          duration: 4000
        });
        return;
      }
      if (!this.newPasswordRetype) {
        this.snackbar.open('You must retype your password to make sure it\'s correct', null, {
          duration: 4000
        });
        return;
      }

      if (this.newPassword !== this.newPasswordRetype) {
        this.snackbar.open('Your new password and retyped password do not match', null, {
          duration: 4000
        });
        return;
      }
    }
    this.updating = true;
    this.apiService.updateAccountSettings(this.currentPassword, this.newPassword, this.newEmail, this.sendOfflineAlerts).subscribe(result => {
      this.updating = false;
      this.snackbar.open(result.response, null, {
        duration: 4000
      });
    });
  }

  updateCustomisation() {
    const hexRegex = /^#[0-9A-F]{6}$/i;

    if (this.brandColours) {
      if (!hexRegex.test(this.brandColours.colour2) || !hexRegex.test(this.brandColours.colour1)) {
        this.snackbar.open('Please provide valid colours for your brand colouring.', null, {
          duration: 4000
        });
        return;
      }
    }

    this.updating = true;
    this.apiService.updateAccountCustomisation(this.brandColours.colour1, this.brandColours.colour2, this.logo, this.backgroundStyle, this.bgimage).subscribe(result => {
      this.updating = false;
      this.snackbar.open(result.response, null, {
        duration: 4000
      });
    });
  }

  selectLogo() {
    this.dialog.open(MediaSelectorComponent, {
      width: '800px',
      data: { type: 'images', selected: [ this.logo ], singleSelection: true },
    }).afterClosed().subscribe((result: IApiMedia[] | boolean) => {
      if (result) {
        this.logo = result[0].location;
      }
    })
  }

  selectbgImage() {
    this.dialog.open(MediaSelectorComponent, {
      width: '800px',
      data: { type: 'images', selected: [ this.bgimage ], singleSelection: true },
    }).afterClosed().subscribe((result: IApiMedia[] | boolean) => {
      if (result) {
        this.bgimage = result[0].location;
      }
    })
  }

  linkSpotify() {
    if (this.spotifyLoading) {
      return;
    }

    this.spotifyLoading = true;
    this.apiService.getSpotifyAuthLogin().subscribe(result => {
      window.location.href = result.url;
      this.spotifyLoading = false;
    })
  }

  linkHail() {
    if (this.hailLoading) {
      return;
    }

    this.hailLoading = true;
    this.apiService.getHailAuthLogin().subscribe(result => {
      window.location.href = result.url;
      this.hailLoading = false;
    })
  }

  linkGoogle() {
    if (this.googleLoading) {
      return;
    }

    this.googleLoading = true;
    this.apiService.getGoogleAuthLogin().subscribe(result => {
      window.location.href = result.url;
      this.googleLoading = false;
    })
  }

  openKamarAuth() {
    this.dialog.open(KamarAuthComponent, {
      data: {
        api: 'https://api.pomp.co.nz/receive/kamar',
        username: this._currentInfo.kamarAuth?.username ?? '',
        password: this._currentInfo.kamarAuth?.password ?? ''
      },
      width: '450px'
    });
  }

}
