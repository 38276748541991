import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MediaSelectorData} from '../../playlist-list/playlist/playlist.component';
import {ApiService} from '../../../../services/api.service';
import {IApiMedia} from '../../../../services/interfaces/IApiMedia';
import {UploadMediaComponent} from '../upload-media/upload-media.component';
import {IUploadedFile} from '../../../../services/interfaces/IApiMediaUpload';
import {CanvaPopupComponent} from '../canva-popup/canva-popup.component';

const IMAGE_MIME_TYPES: string[] = ['image/jpeg', 'image/png', 'image/gif'];
const VIDEO_MIME_TYPES: string[] = ['video/mp4', 'video/quicktime'];

@Component({
  selector: 'app-media-selector',
  templateUrl: './media-selector.component.html',
  styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent implements OnInit {
  loaded: boolean = false;
  private _media: IApiMedia[];
  selectedMedia: IApiMedia[];
  singleSelection: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<MediaSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaSelectorData,
    private apiService: ApiService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.reloadData();
    this.singleSelection = this.data.singleSelection ?? false;
  }

  reloadData(): void {
    this.apiService.getInfo().subscribe(info => {
      this._media = info.media.slice();
      this.selectedMedia = [];
      for (let media of info.media) {
        if (this.data.selected.includes(media.location)) {
          this.selectedMedia.push(media);
        }
      }

      this._media.reverse();

      if (!this._media) {
        this.dialogRef.close(null);
        return;
      }

      this.loaded = true;
    });
  }

  get unselectedMedia(): IApiMedia[] {
    if (this.singleSelection) {
      return this._media;
    }

    return this._media.filter(x => !this.selectedMedia.includes(x) && this.isValidMimetype(x));
  }

  isValidMimetype(media: IApiMedia): boolean {
    return ((this.data.type === 'images' && IMAGE_MIME_TYPES.includes(media.mimetype)) ||
      (this.data.type === 'videos' && VIDEO_MIME_TYPES.includes(media.mimetype)));
  }

  get hasSelectedMedia(): boolean {
    return this.selectedMedia.length > 0;
  }

  selectMedia(media: IApiMedia) {
    if (this.singleSelection) {
      this.selectedMedia = [ media ];
    } else {
      if (!this.selectedMedia.includes(media)) {
        this.selectedMedia.push(media);
      }
    }
  }

  deselectMedia(media: IApiMedia) {
    let index = this.selectedMedia.indexOf(media);

    if (index !== -1) {
      this.selectedMedia.splice(index, 1);
    }
  }

  addAll() {
    this.selectedMedia = this._media.slice();
  }

  removeAll() {
    this.selectedMedia = [];
  }

  save() {
    if (this.data.screen) {
      switch (this.data.type) {
        case 'images':
          this.data.screen.metadata.imagesURLs = this.selectedMedia.map(x => x.location);
          break;

        case 'videos':
          this.data.screen.metadata.videosURLs = this.selectedMedia.map(x => x.location);
          break;
      }
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(this.selectedMedia);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  uploadMedia() {
    const dialogRef = this.dialog.open(UploadMediaComponent);
    dialogRef.afterClosed().subscribe((data: IUploadedFile[] | boolean) => {
      if (typeof data === 'boolean') {
      } else {
        this.handleNewFiles(data);
      }
    });
  }

  createCanva() {
    this.dialog.open(CanvaPopupComponent, {
      disableClose: true,
      width: '400px',
    }).afterClosed().subscribe((data: IUploadedFile[] | boolean) => {
      if (typeof data === 'boolean') {
      } else {
        this.handleNewFiles(data);
      }
    });
  }

  handleNewFiles(files: IUploadedFile[]) {
    for (let file of files) {
      let media: IApiMedia = {
        location: file.location,
        thumb: file.thumb,
        mimetype: file.mimetype,
        originalname: file.originalname,
        size: 0,
        _id: this.uuidv4()
      }
      if (this.isValidMimetype(media)) {
        this._media.push(media);
        this.selectMedia(media);
      }
    }
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
