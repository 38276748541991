<div class="container" *ngIf="loaded">
  <div class="top">
    <h1>Editing Playlist {{ playlist.name }}</h1>
    <div class="playlist-buttons">
       <button mat-flat-button color="primary" (click)="toggleSettings()" *ngIf="loaded && !showingConfigure"><i class="fas fa-cog"></i> Configure Playlist</button>
      <button mat-flat-button color="accent" (click)="onAddScreenClick()" *ngIf="loaded">Add Screen</button>
    </div>
  </div>
  <mat-card class="loading-card" *ngIf="!loaded">
    <app-loading-spinner></app-loading-spinner>
  </mat-card>

  <mat-card class="playlist-settings-card" *ngIf="showingConfigure" @slideInOut>
    <mat-card-header>
      <mat-card-title>Playlist Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field class="name">
        <mat-label>Playlist Name</mat-label>
        <input matInput (keyup)="onNameChange()" [formControl]="nameFormControl">
        <mat-hint>Update your playlist name</mat-hint>
        <mat-error *ngIf="!nameFormControl.valid">Your playlist name must be at least 3 characters and unique.</mat-error>
      </mat-form-field>

      <mat-form-field class="name transition-picker">
        <mat-label>Screen Transition Style</mat-label>
        <mat-select [(ngModel)]="playlist.transitionType" (ngModelChange)="saveChanges()">
          <mat-option value="none">None</mat-option>
          <mat-option value="fade">Fade</mat-option>
          <mat-optgroup label="Slide">
            <mat-option value="slide-left">Right to Left</mat-option>
            <mat-option value="slide-right">Left to Right</mat-option>
            <mat-option value="slide-down">Top to Bottom</mat-option>
            <mat-option value="slide-up">Bottom to Top</mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-hint>Transitions occur when screens change on your device.</mat-hint>
      </mat-form-field>

      <mat-checkbox class="name" [(ngModel)]="playlist.ticker.enabled" >Enable Text Ticker</mat-checkbox>

      <mat-form-field *ngIf="playlist.ticker.enabled" @slideInOut class="name">
        <mat-label>Ticker Message</mat-label>
        <input matInput (keyup)="onNameChange()" [formControl]="tickerFormControl">
        <mat-hint>Your Text Ticker message</mat-hint>
        <mat-error *ngIf="!nameFormControl.valid">Your playlist name must be at least 3 characters and unique.</mat-error>
      </mat-form-field>

      <div class="changes-made" @slideInOut *ngIf="nameFormControl.value !== playlist.name || tickerFormControl.value !== ''">
        <div class="buttons">
          <button mat-flat-button color="primary" (click)="saveNewName()" [disabled]="!nameFormControl.valid">Save Changes</button>
        </div>
      </div>

      <!-- <p class="configure">Configure your playlist to your liking. Add new screens with the "Add Screen" button above and configure, remove screens below and drag them around to re-order.</p>
      <p class="configure">Once the playlist has been configured navigate to the devices section and attach this playlist to your device.</p> -->
    </mat-card-content>
  </mat-card>

  <mat-card class="missing-screen" *ngIf="loaded && playlist.screens.length === 0">
    <mat-card-content>
      <img src="/assets/images/error.svg" alt="Error">
      <h1>Error: You don't have a screen in this playlist</h1>
      <p>To get started please add a screen by clicking the "Add Screen" button above.</p>
    </mat-card-content>

    <!-- <mat-card-actions>
      <button mat-flat-button color="accent" (click)="onAddScreenClick()" class="new-add-screen">Add Screen</button>
    </mat-card-actions> -->
  </mat-card>

  <div class="playlist-list" *ngIf="loaded && playlist.screens.length > 0" cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-card class="playlist-card" *ngFor="let screen of playlist.screens" cdkDrag>
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>{{ screenTypes[screen.type].name }}</mat-card-title>
          <mat-card-subtitle>Displays for {{ screen.duration }} seconds</mat-card-subtitle>
          <div class="icons">
            <button mat-flat-button (click)="beginScreenCancel(screen)"><i class="fas fa-trash-alt delete-icon"></i></button>
          </div>
        </mat-card-title-group>
      </mat-card-header>
      <mat-card-content>
        <div class="property" *ngFor="let prop of screenTypes[screen.type].properties">
          <strong>{{ prop.name }}</strong>
          <ng-container [ngSwitch]="prop.type">
            <div class="value" *ngSwitchCase="'string'">{{ screen.metadata[prop.key] }}</div>
            <div class="value" *ngSwitchCase="'emoji'"><span class="emoji-preview">{{ screen.metadata[prop.key] }}</span></div>
            <div class="value" *ngSwitchCase="'hailOrganisations'">{{ screen.metadata[prop.key] }} selected</div>
            <div class="value" *ngSwitchCase="'googleCalendars'">{{ screen.metadata[prop.key] }} selected</div>
            <div class="value" *ngSwitchCase="'enum'">{{ screen.metadata[prop.key] }} selected</div>
            <div class="value" *ngSwitchCase="'enumMulti'">{{ screen.metadata[prop.key] }} selected</div>
            <div class="value" *ngSwitchCase="'images'">{{ screen.metadata.hasOwnProperty(prop.key) ? screen.metadata[prop.key].length : 0 }} images selected ({{ screen.metadata.hasOwnProperty(prop.key) ? buildMediaSelectedString(screen.metadata[prop.key]) : '' }})</div>
            <div class="value" *ngSwitchCase="'videos'">{{ screen.metadata.hasOwnProperty(prop.key) ? screen.metadata[prop.key].length : 0 }} videos selected ({{ screen.metadata.hasOwnProperty(prop.key) ? buildMediaSelectedString(screen.metadata[prop.key]) : '' }})</div>
          </ng-container>
        </div>

        <div class="property">
          <strong>Display Conditions</strong>
          <div class="value">
            {{ buildConditionString(screen) }}
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="actions">
        <button mat-flat-button color="accent" (click)="configureScreen(screen)">Configure Screen</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<!--  <mat-accordion class="playlist-list" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="loaded">-->
<!--    <mat-expansion-panel class="screen" *ngFor="let screen of playlist.screens; let screenIndex = index" cdkDrag>-->
<!--      <mat-expansion-panel-header>-->
<!--        <mat-panel-title class="title"><span *ngIf="playlistFormControls[screenIndex].invalid" class="data-warning"><i class="fas fa-exclamation-triangle"></i></span>{{ screenTypes[screen.type].name }}</mat-panel-title>-->
<!--        <mat-panel-description class="description">{{ screen.duration }} seconds</mat-panel-description>-->
<!--      </mat-expansion-panel-header>-->
<!--      <div class="top">-->
<!--        <mat-form-field appearance="fill">-->
<!--          <mat-label>Screen Type</mat-label>-->
<!--          <mat-select [(value)]="screen.type" (valueChange)="onTypeChange(screen)">-->
<!--            <mat-option *ngFor="let type of objectKeys(screenTypes)" [value]="type" [disabled]="!canPickPremium && premiumIntegrations.includes(type)">-->
<!--              <span *ngIf="premiumIntegrations.includes(type)"><i class="fas fa-star"></i></span>-->
<!--              {{ fancyScreenNames[type] }}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--        <mat-form-field appearance="fill" class="shorter">-->
<!--          <mat-label>Duration</mat-label>-->
<!--          <input type="number" min="1" matInput placeholder="Duration in seconds" [(ngModel)]="screen.duration" (ngModelChange)="onDurationChange()">-->
<!--          <span matSuffix>Seconds</span>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="content">-->
<!--&lt;!&ndash;        <div *ngFor="let attribute of screenTypes[screen.type]; let i = index" [ngSwitch]="attribute.type">&ndash;&gt;-->
<!--&lt;!&ndash;          <ng-template *ngSwitchCase="'string'" [ngTemplateOutlet]="stringInput" [ngTemplateOutletContext]="{ data: attribute, screen: screen }"></ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;          <ng-template *ngSwitchCase="'images'" [ngTemplateOutlet]="imagesInput" [ngTemplateOutletContext]="{ data: attribute, screen: screen }"></ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;          <ng-template *ngSwitchCase="'videos'" [ngTemplateOutlet]="videosInput" [ngTemplateOutletContext]="{ data: attribute, screen: screen }"></ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->

<!--      <div *ngIf="doesScreenHaveConditions(screen); else noConditions">-->
<!--        <div *ngIf="screen.conditions.dateBased">-->
<!--          This screen will show during these dates:-->
<!--          <ul>-->
<!--            <li *ngFor="let cond of screen.conditions.dateBased">{{ dateToString(cond.start) }} to {{ dateToString(cond.end) }} </li>-->
<!--          </ul>-->
<!--        </div>-->

<!--        <div *ngIf="screen.conditions.dayBased">-->
<!--          This screen will display on these days at the given times:-->
<!--          <ul>-->
<!--            <li *ngFor="let cond of screen.conditions.dayBased">{{ cond.dayName }} from {{ padWithLeadingZero(cond.startHour) }}:{{ padWithLeadingZero(cond.startMinute) }} to {{ padWithLeadingZero(cond.endHour) }}:{{ padWithLeadingZero(cond.endMinute) }}</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <ng-template #noConditions>This screen displays at any time. No conditions have been set.</ng-template>-->
<!--      <button class="edit-conditions" mat-flat-button color="accent" (click)="editConditions(screen)">Edit Conditions</button>-->
<!--      <button class="remove-button" mat-flat-button color="warn" (click)="beginScreenCancel(screen)">Remove Screen</button>-->
<!--    </mat-expansion-panel>-->
<!--  </mat-accordion>-->
<!-- </div> -->

<!--<ng-template #stringInput let-data="data" let-screen="screen" let-formControl="formControl">-->
<!--  <mat-form-field>-->
<!--    <mat-label>{{ data.hint }}</mat-label>-->
<!--    <input matInput> &lt;!&ndash; [(ngModel)]="screen.metadata[data.name]" (ngModelChange)="onMetadataChange()">&ndash;&gt;-->
<!--&lt;!&ndash;    <mat-error *ngIf="formControl.hasError('required')">This field is required</mat-error>&ndash;&gt;-->
<!--  </mat-form-field>-->
<!--</ng-template>-->

<!--<ng-template #imagesInput let-data="data" let-screen="screen">-->
<!--  <div class="media-selection">-->
<!--    <div><strong>{{ screen.metadata.imagesURLs ? screen.metadata.imagesURLs.length : 0 }}</strong> images selected</div>-->
<!--    <button mat-flat-button (click)="selectMedia(screen, 'images')" color="accent">Select Image Media</button>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #videosInput let-data="data" let-screen="screen">-->
<!--  <div class="media-selection">-->
<!--    <div><strong>{{ screen.metadata.videosURLs ? screen.metadata.videosURLs.length : 0 }}</strong> videos selected</div>-->
<!--    <button mat-flat-button (click)="selectMedia(screen, 'videos')" color="accent">Select Video Media</button>-->
<!--  </div>-->
<!--</ng-template>-->

<ng-template #confirmDelete>
  <div class="confirm-delete">
    <h3>Are you sure?</h3>
    <p>Deleting this screen will remove it from the playlist, losing it's configuration.<br>
    Note: You will still need to save the changes to enact this deletion.</p>

    <div class="options">
      <button mat-flat-button (click)="cancelDeletion()">Cancel</button>
      <button mat-flat-button color="warn" (click)="confirmDeletion()">Delete</button>
    </div>
  </div>
</ng-template>

