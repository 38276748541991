<h3>Select Media</h3>
<div class="selector">
  <div class="column">
    <div class="top">
      <h5>Your Media</h5>
      <a class="option" href="#" (click)="$event.preventDefault(); addAll()" *ngIf="!singleSelection">Add All</a>
    </div>
    <div class="media-container" *ngIf="loaded">
      <mat-selection-list [multiple]="false">
        <mat-list-option class="row" (click)="selectMedia(media)" *ngFor="let media of unselectedMedia" [selected]="false">
          <div class="info">
            <img [src]="media.thumb ? media.thumb : media.location">
            <div class="name">{{ media.originalname }}</div>
            <div class="chevron-right" *ngIf="!singleSelection"><i class="fas fa-chevron-right"></i></div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <div class="column">
    <div class="top">
      <h5>Selected Media</h5>
      <a class="option" href="#" (click)="$event.preventDefault(); removeAll()" *ngIf="!singleSelection">Remove All</a>
    </div>
    <div class="media-container" *ngIf="loaded">
      <mat-selection-list [multiple]="false">
        <mat-list-option class="row" [ngClass]="{ single: singleSelection }" (click)="deselectMedia(media)" *ngFor="let media of selectedMedia">
          <div class="info">
            <div class="chevron-left" *ngIf="!singleSelection"><i class="fas fa-chevron-left"></i></div>
            <img [src]="media.thumb ? media.thumb : media.location">
            <div class="name">{{ media.originalname }}</div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
</div>
<div class="buttons">
  <div class="upload-media">
    <button mat-flat-button color="accent" (click)="uploadMedia()">Upload Media</button>
    <button mat-flat-button color="accent" (click)="createCanva()">Create with Canva</button>
  </div>
  <button mat-flat-button color="warn" (click)="cancel()">Cancel Selection</button>
  <button mat-flat-button color="primary" (click)="save()" [disabled]="singleSelection && !hasSelectedMedia">Select Media</button>
</div>
