<div class="container">
  <div class="content account-settings">



    <mat-tab-group>
      <mat-tab label="Account Settings"> 

        <div class="box">
          <h2>Update your account</h2>

          <mat-form-field appearance="fill" [style.width.%]="100">
            <mat-label>Current Password</mat-label>
            <input id="current-password" type="password" matInput placeholder="Your current password" [(ngModel)]="currentPassword">

          </mat-form-field>


          <mat-form-field appearance="fill" [style.width.%]="100">
            <mat-label>New Email</mat-label>
            <input id="new-email" type="email" matInput placeholder="New Email Address" [(ngModel)]="newEmail">

          </mat-form-field>


          <mat-form-field appearance="fill" [style.width.%]="100">
            <mat-label>New Password</mat-label>
            <input id="new-password" type="password" matInput placeholder="New Password" [(ngModel)]="newPassword">

          </mat-form-field>

          <mat-form-field appearance="fill" [style.width.%]="100">
            <mat-label>Confirm New Password</mat-label>
            <input type="password" placeholder="Confirm New Password" matInput [(ngModel)]="newPasswordRetype">

          </mat-form-field>

          <br>
          <br>
          <mat-slide-toggle [(ngModel)]="sendOfflineAlerts">Send alert when device goes offline</mat-slide-toggle>
          <br>
          <!-- <mat-checkbox >Send Offline Alerts</mat-checkbox> -->
          <br>

          <button type="submit" mat-raised-button color="primary" (click)="updateSettings()" [disabled]="updating">Save</button>
        </div>


      </mat-tab>
      <mat-tab label="Brand Settings"> 
        <div class="box">

          <div class="row">
            <div class="col-md-5">
              <h2>Configure your brand settings</h2>
              <p>Brand settings allow you to change how your logo and colours appear in POMP.</p>
              <div class="brand-colours">
                <div class="colour-container">
                  <label>Colour 1</label>
                  <div [(colorPicker)]="brandColours.colour1" [style.background]="brandColours.colour1" cpAlphaChannel="disabled" class="colour"></div>
                </div>
    
                <div class="colour-container">
                  <label>Colour 2</label>
                  <div [(colorPicker)]="brandColours.colour2" [style.background]="brandColours.colour2" cpAlphaChannel="disabled" class="colour"></div>
                </div>
              </div>
              <label>Update your brand colours by clicking the colour and selecting from the colour picker.</label>

            </div>

            <div class="col-md-6 offset-md-1">

              <div class="img-settings logo-settings">
                <h2>Your Logo</h2>
                <div class="logo-display">
                  <div class="img-box">
                    <img [src]="logoThumbnail" *ngIf="logoThumbnail">
                  </div>
                  <button class="select-logo" mat-raised-button color="primary" (click)="selectLogo()">Select Logo</button>
                </div>
              </div>
    
              <div class="img-settings background-image-settings">
                <h2>Background Image</h2>
                <div class="bg-image-display">
                  <div class="img-box">
                    <img [src]="bgimage" *ngIf="bgimage">
                  </div>
                  <button class="select-logo" mat-raised-button color="primary" (click)="selectbgImage()">Select Background Image</button>
                </div>
              </div>

            </div>
          </div>

          


          <div class="background-style">
            <mat-form-field>
              <mat-label>Background Style</mat-label>
              <mat-select [(ngModel)]="backgroundStyle">
                <mat-option value="animated">Animated Gradient</mat-option>
                <mat-option value="gradient">Gradient</mat-option>
                <mat-option value="static">Static</mat-option>
                <mat-option value="image">Image</mat-option>
              </mat-select>
            </mat-form-field>

          </div>


          <br> <br>
          <button type="submit" mat-raised-button color="primary" (click)="updateCustomisation()" [disabled]="updating">Save</button>
        </div>  
      </mat-tab>
      <mat-tab label="Billing"> 
        <div class="box billing">
          <h2>Billing</h2>
          <ng-container *ngIf="!hasSub else subData">
            <p>You have not yet started a subscription. There is no data to display.</p>
          </ng-container>
          <ng-template #subData>
            <p>Your subscription info</p>
            <div class="planName">Your plan: {{ billingInfo.planName }}</div>
            <div class="planDetails">
              {{ billingInfo.currentDevices }}/{{ billingInfo.maxDevices }} devices<br>
              {{ billingInfo.currentPlaylists }}/{{ billingInfo.maxPlaylists }} playlists<br>
              {{ billingInfo.currentStorage }}MB/{{ billingInfo.maxStorage }}MB storage<br>
            </div>
            <a mat-raised-button color="primary" [routerLink]="['/', 'dashboard', 'subscriptions']">Manage your subscription</a>
            <a mat-raised-button color="primary" [routerLink]="['/', 'dashboard', 'payment']">Payment Portal</a>
          </ng-template>
        </div>  
      </mat-tab>
      <mat-tab label="Integrations"> 
      
        <div class="box spotify">
          <h2>Your Integrations</h2>

          <h4>Spotify</h4>
          <p>Our spotify integration will allow you to show Spotify currently playing on your screens.</p>
            <button class="connect-spotify" (click)="linkSpotify()" mat-raised-button [disabled]="spotifyLoading" *ngIf="!linkedSpotify else linkedSpotifyMessage">
              <app-loading-spinner *ngIf="spotifyLoading else spotifyText" style="font-size: 15px"></app-loading-spinner>
            </button>
          <hr>

          <h4>Hail.to</h4>
          <p>Connect your Hail.to account to show hail articles on your screens</p>
            <button class="connect-hail" (click)="linkHail()" mat-raised-button [disabled]="hailLoading" *ngIf="!linkedHail else linkedHailMessage">
              <app-loading-spinner *ngIf="hailLoading else hailText" style="font-size: 15px"></app-loading-spinner>
            </button>
          <hr>

          <h4>Google Calendar</h4>
          <p>Connect your Google account to show hail articles on your screens</p>
            <a class="connect-google" (click)="linkGoogle()" [disabled]="googleLoading" *ngIf="!linkedGoogle else linkedGoogleMessage">
              <app-loading-spinner *ngIf="googleLoading else googleText" style="font-size: 15px"></app-loading-spinner>
            </a>
          <hr>

          <br>

          <h4>KAMAR</h4>
          <p>Connect to Kamar to show live notices and calendar data.</p>
          <div class="kamar-buttons">
            <button mat-raised-button color="primary" (click)="openKamarAuth()">KAMAR Authentication Config</button>
          </div>

        </div>
        
      </mat-tab>
    </mat-tab-group>






<ng-template #loadingContainer>
  <mat-card class="loading-card">
    <app-loading-spinner></app-loading-spinner>
  </mat-card>
</ng-template>

<ng-template #linkedSpotifyMessage>
  Your Spotify account is connected!
</ng-template>

<ng-template #spotifyText>
  <i class="fab fa-spotify"></i> Link Spotify Account
</ng-template>

<ng-template #linkedHailMessage>
  Your Hail account is connected!
</ng-template>

<ng-template #hailText>
   Link Hail Account
</ng-template>

<ng-template #linkedGoogleMessage>
  Your Google account is connected!
</ng-template>

<ng-template #googleText>
  <img src="assets/images/btn_google_signin_dark_normal_web@2x.png" draggable="false" style="max-width: 200px;"/>
</ng-template>
