import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {IApiMediaUpload, IUploadedFile} from '../../../../services/interfaces/IApiMediaUpload';

@Component({
  selector: 'app-upload-media',
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.scss']
})
export class UploadMediaComponent implements OnInit {
  @ViewChild('fileInput') private fileInput: ElementRef;

  dropHovered: boolean = false;
  uploading: boolean = false;
  progress: number = 0;

  constructor(
    private apiService: ApiService,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UploadMediaComponent>
  ) { }

  ngOnInit(): void {
  }

  browseLocalFiles() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    this.uploading = true;
    this.apiService.uploadMedia(Array.from(files)).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        this.ngZone.run(() => {
          this.progress = Math.round(100 * event.loaded / event.total);
        })
      } else if (event instanceof HttpResponse) {
        let message: IApiMediaUpload = JSON.parse(event.body);
        this.onFileInputFinish(message.response, message.files);
      }
    }, error => {
      this.onFileInputFinish('An unknown error occurred, try again later.')
    });
  }

  onFileInputFinish(message: string, files: IUploadedFile[] = []) {
    this.ngZone.run(() => {
      this.uploading = false;
      this.snackBar.open(message, null, {
        duration: 3000
      });
      this.dialogRef.close(files);
    });
  }

  onFileDropHovered(hovered: boolean) {
    this.dropHovered = hovered;
  }
}
