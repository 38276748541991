import {Direct} from 'protractor/built/driverProviders';
import {Directive, HostListener, Output, EventEmitter, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appFileDrag]'
})
export class FileDragDirective {
  @Output()
  fileHovered = new EventEmitter<boolean>();

  @Output()
  filesDropped = new EventEmitter<FileList>();

  @Input()
  disabled: boolean = false;

  @HostListener('dragover', ['$event'])
  onDragOver(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fileHovered && !this.disabled) {
      this.fileHovered.emit(true);
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fileHovered) {
      this.fileHovered.emit(false);
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.fileHovered) {
      this.fileHovered.emit(false);
    }

    const files = event.dataTransfer.files;
    if (!this.disabled && this.filesDropped && files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
