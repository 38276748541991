<h3>Confirm Deletion</h3>
<p>
  Are you sure you want to delete <strong>{{ data.name }}</strong>?<br>
  Once deleted, it is not recoverable!
</p>
<p>
  <mat-checkbox class="example-margin" [(ngModel)]="deleteAgreed">Confirm deletion of <strong>{{data.name}}</strong></mat-checkbox>
</p>
<div class="buttons">
  <button mat-flat-button (click)="cancelDelete()">Cancel</button>
  <button mat-flat-button color="primary" (click)="confirmDelete()" [disabled]="!deleteAgreed">Delete</button>
</div>
