import {AfterContentInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, AfterContentInit {

  constructor(
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.authService.logout();
  }

  ngAfterContentInit() {

  }

}
