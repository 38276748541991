import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {IApiSubscription} from '../../../services/interfaces/IApiSubscription';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IApiAccountInfoSubscription} from '../../../services/interfaces/IApiAccountInfo';

@Component({
  selector: 'app-choose-subscription',
  templateUrl: './choose-subscription.component.html',
  styleUrls: ['./choose-subscription.component.scss']
})
export class ChooseSubscriptionComponent implements OnInit, OnDestroy {

  plansLoaded: boolean = false;
  stripeLoaded: boolean = false;
  subPlans: IApiSubscription[];
  waiting: boolean = false;

  subscriptionData: IApiSubscription;
  hasSubscription: boolean;

  private timeoutId: number;
  private request$: Subscription;
  private stripe: stripe.Stripe;
  private hasStripeInfoOnAccount: boolean;

  constructor(
    private apiService: ApiService,
    private ngZone: NgZone,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    if (this.request$) {
      this.request$.unsubscribe();
    }

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  loadData() {
    this.apiService.getInfo().subscribe(info => {
      this.hasSubscription = info.subscription.planId !== false;
      this.subscriptionData = this.hasSubscription && (info.subscription as IApiAccountInfoSubscription).data;
      this.stripe = Stripe(info.stripePublicKey);
      this.stripeLoaded = true;
      this.hasStripeInfoOnAccount = info.subscription.stripeActive;
    });

    this.apiService.getSubscriptionPlans().subscribe(plans => {
      this.ngZone.run(() => {
        this.subPlans = plans;
        this.plansLoaded = true;
      });
    });
  }

  purchasePlan(plan: IApiSubscription, event: Event) {
    event.preventDefault();

    if (this.waiting) {
      return;
    }

    this.waiting = true;
    if (this.hasStripeInfoOnAccount) {
      this.apiService.changeSubscription(plan.id).subscribe(result => {
        this.waiting = false;

        this.loadData();
      })
    } else {
      this.request$ = this.apiService.createCheckoutSession(plan.id).subscribe(result => {
        console.log(result)
        this.stripe.redirectToCheckout({
          sessionId: result.sessionId
        }).then(data => {
          this.waiting = false;
          if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
          }
        })
      })
    }
  }

  timeout() {
    this.waiting = false;
    if (this.request$) {
      this.request$.unsubscribe();
    }

    this.snackbar.open('An error occurred creating your subscription. Try again in a second.', null, {
      duration: 5000
    })
  }

}
