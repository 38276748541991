<div class="container"  *ngIf="!loaded">
  <mat-card class="loading-card">
    <app-loading-spinner></app-loading-spinner>
  </mat-card>
  </div>

<div class="container head-wrap" *ngIf="loaded && media.length !== 0">
    <div class="media-title">
      <h1>Your Media</h1>
      <div class="current-usage">
        You are currently using <strong>{{currentUsage}}MB</strong> out of <strong>{{ maxUsage }}MB</strong>. Your storage is <strong>{{ percentageStorageFull }}%</strong> full.
        <mat-progress-bar mode="determinate" [value]="percentageStorageFull"></mat-progress-bar>
      </div>
    </div>
    <div class="options">
      <button mat-flat-button color="primary" (click)="openMediaUpload()" *ngIf="loaded">Add Media</button>
      <button mat-flat-button color="accent" (click)="openCanvaCreate()" *ngIf="loaded">Create with Canva</button>
    </div>
</div>


<div class="container">
  <div class="no-media" *ngIf="loaded && media.length == 0">
    <img src="/assets/images/upload.svg">

    <h1>You haven't uploaded any media yet</h1>

    <div class="how-to">
      Get started by uploading some media or clicking the create with canva button.
    </div>

    <div class="options">
      <button mat-flat-button color="primary" (click)="openMediaUpload()" *ngIf="loaded">Add Media</button>
      <button mat-flat-button color="accent" (click)="openCanvaCreate()" *ngIf="loaded">Create with Canva</button>
    </div>
  </div>
</div>
<div class="allMedia">
  <div class="config">
    <div class="filters">
      <mat-chip-list>
        <mat-chip (click)="setFilter('all')" class="filter" [ngClass]="{ selected: filterType === 'all' }">All</mat-chip>
        <mat-chip (click)="setFilter('images')" class="filter" [ngClass]="{ selected: filterType === 'images' }">Images</mat-chip>
        <mat-chip (click)="setFilter('videos')" class="filter" [ngClass]="{ selected: filterType === 'videos' }">Videos</mat-chip>
        <mat-chip (click)="setFilter('canva')" class="filter" [ngClass]="{ selected: filterType === 'canva' }">Canva</mat-chip>
      </mat-chip-list>
    </div>

    <div class="sorts">
      <button mat-flat-button class="sort" [ngClass]="{ selected: sortType === 'name' }" (click)="setSort('name')"><ng-container [ngTemplateOutlet]="sortDirection" *ngIf="sortType === 'name'"></ng-container> Name</button>
      <button mat-flat-button class="sort" [ngClass]="{ selected: sortType === 'fileSize' }" (click)="setSort('fileSize')"><ng-container [ngTemplateOutlet]="sortDirection" *ngIf="sortType === 'fileSize'"></ng-container> File Size</button>

      <ng-template #sortDirection>
        <span *ngIf="sortAscending"><i class="fas fa-caret-down"></i></span>
        <span *ngIf="!sortAscending"><i class="fas fa-caret-up"></i></span>
      </ng-template>
    </div>
  </div>
  <div class="media-list">
    <div class="media-box" *ngFor="let media of filteredMedia">
      <div class="mediaItem" style="background-image:url({{media.thumb}});"></div>
      <!-- <img [src]="media.thumb ? media.thumb : media.location" class="media-element"> -->
      <div class="top">
        <div class="name">{{ media.originalname }}</div>

        <div class="btns">
          <span *ngIf="media.canvaDesignId" (click)="openCanvaCreate(media)">
            <i class="fas fa-edit icon" ></i>
            </span>
          <span (click)="removeMedia(media)">
          <i class="fas fa-trash icon" ></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
