import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['../login/login.component.scss', './register.component.scss']
})
export class RegisterComponent implements OnInit {

  private data: { email: string, password: string, retypePassword: string };

  form: FormGroup;

  awaitingVerify: boolean;

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) { }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get passwords(): FormGroup {
    return this.form.get('passwordGroup') as FormGroup;
  }

  ngOnInit(): void {
    this.data = { email: '', password: '', retypePassword: '' };
    this.form = new FormGroup({
      email: new FormControl(this.data.email, [
        Validators.required,
        Validators.email
      ]),
      passwordGroup: new FormGroup({
        password: new FormControl(this.data.password, Validators.required),
        retypePassword: new FormControl(this.data.retypePassword, Validators.required)
      }, { validators:  [ this.passwordsMatch ] })
    });
  }

  attemptLogin() {
    if (this.email.invalid || this.passwords.invalid) {
      return;
    }

    this.authService.register({ email: this.email.value, password: this.passwords.controls.password.value }).subscribe(this.onData.bind(this));
  }

  onData(data: { success: boolean, response?: string }) {
    if (data.success) {
      if (data.response.includes('verify')) {
        this.awaitingVerify = true;
      }
    } else {
      this.email.setErrors({ duplicate: true });
    }

    this.snackbar.open(data.response, null, {
      duration: 3000
    });
  }

  private passwordsMatch(c: AbstractControl): { [key: string]: boolean } | null {
    const passwordControl = c.get('password');
    const confirmControl = c.get('retypePassword');

    if (passwordControl.pristine || confirmControl.pristine) {
      return null;
    }

    if (passwordControl.value === confirmControl.value) {
      return null;
    }
    return { match: true };
  }
}
