import {ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {IApiDevice} from '../../services/interfaces/IApiDevice';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import {BurgerService} from '../../services/burger.service';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('drawer') burgerMenuDrawer: MatDrawer
  public devices: IApiDevice[];
  public playlists: any[];
  public hasSubscription: boolean;
  public loaded: boolean = false;

  public mobileQuery: MediaQueryList;
  public menuOpened: boolean = true;

  private _mobileQueryListener: () => void;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,

    public burgerService: BurgerService
  ) { }

  get routerPath(): string {
    return this.router.url;
  }

  ngOnInit(): void {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      if (!this.mobileQuery.matches) {
        this.menuOpened = true;
        this.burgerService.setMenuVisibility(true);
        this.burgerMenuDrawer.open();
      } else {
        this.menuOpened = false;
        this.burgerMenuDrawer.close();
      }

      this.changeDetectorRef.detectChanges();
    }
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.menuOpened = !this.mobileQuery.matches;
    this.apiService.getInfo().subscribe(data => {
      this.hasSubscription = data.subscription.planId !== false;
      this.loaded = true;
      if (data.devices) {
        this.devices = data.devices;
        this.playlists = data.playlists;
      }
    })
  }

  onNavItemClick() {
    if (this.mobileQuery.matches) {
      this.burgerService.toggleMenu();
    }
  }
}
