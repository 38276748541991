<div class="container">
  <div class="popup success" *ngIf="successMessage">{{ successMessage }}</div>
  <div class="popup error" *ngIf="errorMessage">{{ errorMessage }}</div>

  <div class="content">
    <div class="header">Manage your Device</div>
    <div class="inner-content">
      <label for="nickname">Your device's nickname</label>
      <input type="text" id="nickname" [(ngModel)]="nickname">

      <button class="button-blue submit-button" (click)="updateDevice()">Save Changes</button>
    </div>
</div>
