import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Subscription} from 'rxjs';
import {IApiClaimDeviceResult} from '../../../../services/interfaces/IApiClaimDeviceResult';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-register-device',
  templateUrl: './register-device.component.html',
  styleUrls: ['./register-device.component.scss']
})
export class RegisterDeviceComponent implements OnInit, OnDestroy {

  public errorMessage: string;
  public registerCode: string = '';
  public formControl: FormControl;

  private claimDevice$: Subscription;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<RegisterDeviceComponent>
  ) { }

  ngOnInit(): void {
    this.formControl = new FormControl(this.registerCode, [
      Validators.required
    ]);
  }

  ngOnDestroy() {
    if (this.claimDevice$) {
      this.claimDevice$.unsubscribe();
    }
  }

  tryRegisterDevice() {
    let code = this.formControl.value.trim();
    if (!code) {
      this.errorMessage = 'You must enter a registration code.';
      return;
    }

    this.apiService.claimDevice(code).subscribe(d => {
      if (d.success) {
        this.dialogRef.close(d.success);
      } else {
        this.formControl.setErrors({'incorrect': true});
        this.errorMessage = d.response;
      }
    });
  }

  cancelRegister() {
    this.dialogRef.close(null);
  }
}
