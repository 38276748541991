<h4>Register a Device</h4>
<p>Enter your device's registration code here to assign it to your account.</p>
<mat-form-field class="full-width">
  <mat-label>Code</mat-label>
  <input matInput autocomplete="off" autocapitalize="none" (keyup.enter)="tryRegisterDevice()" [formControl]="formControl">
  <mat-hint>Find this code on your device</mat-hint>
  <mat-error *ngIf="!this.formControl.valid">{{ errorMessage }}</mat-error>
</mat-form-field>

<div class="options">
  <button mat-flat-button (click)="cancelRegister()">Cancel</button>
  <button mat-flat-button color="primary" (click)="tryRegisterDevice()">Add Device</button>
</div>
