<div class="container">
  <div class="content login">
    <mat-card>
      <mat-card-title>Sign into your account</mat-card-title>
      <form [formGroup]="form" (submit)="attemptLogin()">
        <mat-form-field appearance="standard">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email Address" formControlName="email">
          <mat-error *ngIf="email.hasError('required')">Your email is <strong>required</strong></mat-error>
          <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">Please enter a valid email address</mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard">
          <mat-label>Password</mat-label>
          <input type="password" matInput placeholder="Password" formControlName="password">
          <mat-error *ngIf="password.hasError('required')">Your password is <strong>required</strong></mat-error>
        </mat-form-field>
        <re-captcha
        #captchaRef="reCaptcha"
        (resolved)="onCaptchaResolved($event)"
        (error)="onCaptchaError($event)"
        errorMode="handled"
        [siteKey]="captchaSiteKey"
        size="invisible"
      ></re-captcha>
        <a [routerLink]="['../', 'forgot-password']" class="forgotPasswordLink">Forgotten your password?</a>
        <button type="submit" mat-raised-button color="primary" [disabled]="form.pristine">Log In</button>
      </form>
    </mat-card>
    <a [routerLink]="['../', 'register']" class="createAccountLink">New here? Create an account</a>
  </div>
</div>