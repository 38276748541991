import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {DeviceListComponent} from './device-list/device-list.component';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from '../../app-routing.module';
import {ApiService} from '../../services/api.service';
import { RegisterDeviceComponent } from './device-list/register-device/register-device.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PlaylistListComponent } from './playlist-list/playlist-list.component';
import { PlaylistComponent } from './playlist-list/playlist/playlist.component';
import { DeviceComponent } from './device/device.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import { MediaLibraryComponent } from './media-library/media-library.component';
import {MatButtonModule} from '@angular/material/button';
import { UploadMediaComponent } from './media-library/upload-media/upload-media.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FileDragDirective} from '../../directives/fileDrag.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { MediaSelectorComponent } from './media-library/media-selector/media-selector.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ConfirmDeleteComponent } from './util/confirm-delete/confirm-delete.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { PaymentComponent } from './payment/payment.component';
import { LoadingSpinnerComponent } from './util/loading-spinner/loading-spinner.component';
import {ChooseSubscriptionComponent} from './choose-subscription/choose-subscription.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ScreenSelectorComponent } from './playlist-list/playlist/screen-selector/screen-selector.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatChipsModule} from '@angular/material/chips';
import { ScreenConfiguratorComponent } from './playlist-list/playlist/screen-configurator/screen-configurator.component';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import { EmojiPickerComponent } from './util/emoji-picker/emoji-picker.component';



@NgModule({
  declarations: [
    DashboardComponent,
    DeviceListComponent,
    RegisterDeviceComponent,
    PlaylistListComponent,
    PlaylistComponent,
    DeviceComponent,
    MediaLibraryComponent,
    UploadMediaComponent,
    FileDragDirective,
    MediaSelectorComponent,
    ConfirmDeleteComponent,
    PaymentComponent,
    LoadingSpinnerComponent,
    ChooseSubscriptionComponent,
    ScreenSelectorComponent,
    ScreenConfiguratorComponent,
    EmojiPickerComponent,
  ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        MatSidenavModule,
        MatListModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule,
        DragDropModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatStepperModule,
        MatChipsModule,
        MatTabsModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        PickerModule
    ],
  exports: [
    LoadingSpinnerComponent
  ],
  providers: []
})
export class DashboardModule { }
