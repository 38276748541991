import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Observable, Subscription} from 'rxjs';
import {IApiDevice} from '../../../services/interfaces/IApiDevice';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {RegisterDeviceComponent} from './register-device/register-device.component';
import {IApiPlaylist} from '../../../services/interfaces/IApiPlaylist';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IApiMedia} from '../../../services/interfaces/IApiMedia';
import {ConfirmDeleteComponent} from '../util/confirm-delete/confirm-delete.component';
import {FormControl, Validators} from '@angular/forms';
import {IApiAccountInfoSubscription} from '../../../services/interfaces/IApiAccountInfo';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit, OnDestroy {
  public message: string = '';
  public devices: IApiDevice[];
  public playlists: IApiPlaylist[];
  public deviceFormControls: { [key: string]: FormControl };
  public loaded: boolean = false;

  private maxDevices: number;
  private onInfo$: Subscription;

  private updateIntervalId: number;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.deviceFormControls = {};
    this.getDevices();

    this.route.queryParams.subscribe(params => {
      if (params['registerSuccess']) {
        this.message = 'Device successfully registered!';
      }
    })

    this.updateIntervalId = window.setInterval(this.getDevices.bind(this), 60000);
  }

  ngOnDestroy() {
    if (this.onInfo$) {
      this.onInfo$.unsubscribe();
    }

    if (this.updateIntervalId) {
      window.clearInterval(this.updateIntervalId);
    }
  }

  showRegister() {
    if (this.devices.length >= this.maxDevices) {
      this.snackBar.open(`You can only register a maximum of ${this.maxDevices} devices. Consider upgrading your subscription to register more.`, null, {
        duration: 5000
      });

      return;
    }

    this.dialog.open(RegisterDeviceComponent).afterClosed().subscribe(result => {
      if (result) {
        this.loaded = true;
        this.getDevices();
      }
    });
  }

  private getDevices() {
    this.onInfo$ = this.apiService.getInfo().subscribe(result => {
      this.loaded = true;
      this.maxDevices = (result.subscription as IApiAccountInfoSubscription).data.limitations.devices;
      if (result.devices) {
        for (let device of result.devices) {
          if (this.deviceFormControls.hasOwnProperty(device._id)) {
            continue;
          }

          this.deviceFormControls[device._id] = new FormControl(device.nickName ?? '', [
            // Validators.min(4),
            Validators.required
          ]);

          // this.deviceFormControls[device._id].disable({onlySelf: true, emitEvent: false});

          this.deviceFormControls[device._id].valueChanges.subscribe(value => {
            this.validateNewNickname(value, device);
          })
        }
      }


      this.devices = result.devices;
      this.playlists = result.playlists;
    });
  }

  onDataChanged(device: IApiDevice) {
    device.dataChanged = true;
  }

  validateNewNickname(value: string, device: IApiDevice) {
    let formControl = this.deviceFormControls[device._id];
    let dupes = this.devices.find(x => x.nickName && x.nickName.toLowerCase() === value.toLowerCase() && x._id !== device._id);
    if (dupes) {
      formControl.setErrors({ duplicate: true });
    }

    if (value.length < 3) {
      formControl.setErrors({ dumbError: true });
    }

    if (value !== device.nickName) {
      device.dataChanged = true;
    }
  }

  updateDevice(device: IApiDevice) {
    console.log(this.deviceFormControls)
    let formControl = this.deviceFormControls[device._id];
    let nickName: string = formControl.value ?? '';
    let dupes = this.devices.find(x => x.nickName && x.nickName.toLowerCase() === nickName.toLowerCase() && x._id !== device._id);
    if (dupes) {
      formControl.setErrors({ duplicate: true });
      formControl.markAsTouched();
      return;
    } else if (nickName.length < 3) {
      formControl.setErrors({ min: true });
      formControl.markAsTouched();
      return;
    }

    this.apiService.saveDevice(device._id, device.playlist, nickName, device.orientation).subscribe(result => {
      if (result.status) {
        device.dataChanged = false;
        device.nickName = nickName;
        this.snackBar.open('Device successfully updated', null, {
          duration: 3000
        });
      } else {
        this.snackBar.open('An error occurred: ' + result.response, null, {
          duration: 3000
        })
      }
    })
  }

  getLastUsage(date: string) {
    // Make a fuzzy time
    const delta = Math.round((+new Date() - Date.parse(date)) / 1000);
    const minute = 60,
      hour = minute * 60,
      day = hour * 24,
      week = day * 7;

    let relative;

    if (delta < 5 * minute) {
      relative = 'Connected to POMP'
    } else if (delta < hour) {
      relative = `last seen ${Math.floor(delta / minute)} minutes ago`;
    } else if (Math.floor(delta / hour) == 1) {
      relative = `last seen 1 hour ago`;
    } else if (delta < day) {
      relative = Math.floor(delta / hour) + ' hours ago';
      relative = `last seen ${Math.floor(delta / hour)} hours ago`;
    } else if (delta < day * 2) {
      relative = `last seen 1 day ago`;
    } else {
      relative = `last seen ${Math.floor(delta / day)} days ago`;
    }

    return relative;
  }

  removeDevice(device: IApiDevice) {
    this.dialog.open(ConfirmDeleteComponent, {
      width: '500px',
      data: { name: device.nickName }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteDevice(device._id).subscribe((data: { status: boolean }) => {
          this.ngZone.run(() => {
            if (data.status) {
              let index = this.devices.indexOf(device);
              if (index > -1) {
                this.devices.splice(index, 1);

                this.snackBar.open('Device successfully deleted', null, {
                  duration: 3000
                });
              }
            } else {
              this.snackBar.open('Failed to delete device.', null, {
                duration: 3000
              });
            }
          });
        })
      }
    });
  }

  getPlaylistForDevice(device: IApiDevice): IApiPlaylist | null {
    if (device.playlist) {
      return this.playlists.find(x => x._id === device.playlist);
    }

    return null;
  }
}
