import {ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from './services/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {ApiService} from './services/api.service';
import {MediaMatcher} from '@angular/cdk/layout';
import {BurgerService} from './services/burger.service';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'POMP';

  @ViewChild('drawer') burgerMenuDrawer: MatDrawer
  public mobileQuery: MediaQueryList;
  public menuOpened: boolean = true;


  private _mobileQueryListener: () => void;

  constructor(
    private authService: AuthService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,

    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    public burgerService: BurgerService
  ) {
  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {

        let child = this.activatedRoute.firstChild;
        while (child && child.firstChild) {
          child = child.firstChild;
        }
        if (child && child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((title: string) => {
      this.titleService.setTitle(`${this.title} - ${title}`);
    });

    this.apiService.getInfo().subscribe(info => {
      let component = this.activatedRoute.snapshot.firstChild;
      if (info.session && !component) {
        this.router.navigate([ 'dashboard' ]);
      } else if (!component) {
        this.router.navigate([ 'account/login' ])
      }
    });


    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      if (!this.mobileQuery.matches) {
        this.menuOpened = true;
        this.burgerService.setMenuVisibility(true);
        this.burgerMenuDrawer.open();
      } else {
        this.menuOpened = false;
        this.burgerMenuDrawer.close();
      }

      this.changeDetectorRef.detectChanges();
    }
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    this.menuOpened = !this.mobileQuery.matches;
  }
  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  onBurgerClick() {
    console.log('lets click burger!')
    this.burgerService.toggleMenu()
  }

  onNavItemClick() {
    if (this.mobileQuery.matches) {
      this.burgerMenuDrawer.toggle();
    }
  }

  openDrawer() {
    if (this.burgerMenuDrawer) {
      this.burgerMenuDrawer.toggle();
    }
  }
}
