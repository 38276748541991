import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IApiMedia} from '../../../../services/interfaces/IApiMedia';

interface CanvaMediaData {
  media: IApiMedia | null;
}

declare global {
  interface Window { canvaApi: any; Canva: any; }
}

window.canvaApi = window.canvaApi || {};
window.Canva = window.Canva || {};

@Component({
  selector: 'app-canva-popup',
  templateUrl: './canva-popup.component.html',
  styleUrls: ['./canva-popup.component.scss']
})
export class CanvaPopupComponent implements OnInit {
  loading: boolean = true;
  saving: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<CanvaPopupComponent>,
    private apiService: ApiService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: CanvaMediaData
  ) { }

  ngOnInit(): void {
    let onComplete = () => {
      if (this.data && this.data.media) {
        this.editCanva(this.data.media);
      }

      this.loading = false;
    };


    if (Object.keys(window.canvaApi).length != 0) {
      onComplete();
    } else {
      let script = document.createElement('script');
      script.src = 'https://sdk.canva.com/designbutton/v2/api.js';
      script.onload = async () => {
        if (window.Canva && window.Canva.DesignButton) {
          window.canvaApi = await window.Canva.DesignButton.initialize({
            apiKey: 'j57qcHAgqFSZ6bLE41W_ZqL6',
          });

          onComplete();
        }
      }
      document.body.appendChild(script);
    }
  }

  designCanva(fileType, designType){
    window.canvaApi.createDesign({
      editor: {
        publishLabel: 'Save to POMP',
        fileType: fileType,
      },
      design: {
        type: designType,
        dimensions: {
          height: '1080',
          width: '1920',
        },
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: (options) => {
        this.onDesignPublish(options, fileType);

      },
      onDesignClose: () => {
        // Triggered when editor is closed.
      },
    });
  }


  editCanva(media: IApiMedia) { //fileType, designType, designId
    const IMAGE_MIME_TYPES: string[] = ['image/jpeg', 'image/png', 'image/gif'];
    let fileType = IMAGE_MIME_TYPES.includes(media.mimetype) ? 'png' : 'mp4';
    let designType = fileType === 'png' ? 'LargeRectangleAd' : 'Video';
    let designId = media.canvaDesignId;

    window.canvaApi.editDesign({
      editor: {
        publishLabel: 'Save to POMP',
        fileType: fileType,
      },
      design: {
        type: designType,
        id: designId,
        dimensions: {
          height: '1080',
          width: '1920',
        },
      },
      onDesignOpen: ({ designId }) => {
        // Triggered when editor finishes loading and opens a new design.
        // You can save designId for future use.
      },
      onDesignPublish: (options) => {
        this.onDesignPublish(options, fileType);

        //console.log(exportUrl,designId);

      },
      onDesignClose: () => {
        this.dialogRef.close(false);
      },
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  private onDesignPublish(options, fileType: string) {
    this.saving = true;

    this.apiService.canvaDesignPublish(options.designId, options.exportUrl, options.designTitle, fileType).subscribe(info => {
      this.snackbar.open(info.response, null, {
        duration: 4000
      });
      this.dialogRef.close(info.files);
    });
  }
}
